class PayorCategory {
  id: number;
  name: string;
  color: string;

  constructor(id?: number, name?: string, color?: string) {
    this.id = id ?? 0;
    this.name = name ?? '';
    this.color = color ?? '000000';
  }
}

export default PayorCategory;
