import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import UserComponent from '../admin/users/User';
import Box from '@mui/material/Box/Box';

interface Props {
  notify: (title: string, message: string, variant: 'success' | 'error' | 'warning' | 'info') => void;
  userId: number;
}

const Account: FC<Props> = (props) => {
  const { userId, notify } = props;
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '20px 50px 60px 50px',
          borderRadius: '20px',
          position: 'relative',
          width: 'calc(100vw - 240px)',
          marginLeft: '240px',
          minHeight: 'calc(100vh - 100px)',
        }}
      >
        <Box sx={{ paddingTop: '3rem' }}>
          <UserComponent isAdminPage={false} notify={notify} userId={userId} />
        </Box>
      </Paper>
    </Grid>
  );
};
export default Account;
