import { useState, useEffect } from 'react';

export default function useDebounce<T = string>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function search<T extends object>(arg: T[], input: string, property: keyof T): T[] {
  return arg.filter((item) => {
    const value = item[property];
    return typeof value === 'string' && value.toLowerCase().includes(input.toLowerCase());
  });
}
