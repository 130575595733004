import React, { useState, FC } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import User from '../../shared/types/user';
import Grid from '@mui/material/Grid';
import { useSession } from '../hooks/useSession';
import { networkError } from '../../api/base';
import Box from '@mui/material/Box/Box';
import Switch from '@mui/material/Switch/Switch';
import UserFlags from '../../shared/enums/userFlags';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { hasFlag, toggleFlag } from '../../shared/enums/extensions';
import { updateUser } from '../../api/users/requests';
import ConfirmDialog from '../dialogs/ConfirmDialog';

interface Props {
  user: User;
  locked: boolean;
  dirty: boolean;
  setDirty: React.Dispatch<boolean>;
  showConfirm: boolean;
  setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: React.Dispatch<User | undefined>;
  setUserId?: React.Dispatch<number>;
  setWasDirty?: React.Dispatch<boolean>;
  setError: React.Dispatch<string>;
  setSuccessMessage: React.Dispatch<string>;
  isAdminPage: boolean;
}

const UserProfile: FC<Props> = (props) => {
  const {
    user,
    locked,
    setDirty,
    showConfirm,
    setShowConfirm,
    dirty,
    setUser,
    setUserId,
    setWasDirty,
    setSuccessMessage,
    setError,
    isAdminPage,
  } = props;
  const { token } = useSession();
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [isActive, setIsActive] = useState(user.isActive);
  const [loading, setLoading] = useState(false);

  // Events
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (dirty) {
      setShowConfirm(true);
    } else {
      setUser && setUser(undefined);
      setUserId && setUserId(0);
    }
  };

  const setFields = (user: User) => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setIsActive(user.isActive);
    setUser(user);
  };

  const update = async () => {
    const abortController = new AbortController();
    const onSuccess = (user: User) => {
      setLoading(false);
      setFields(user);
      setSuccessMessage('Changes saved!');
      setDirty(false);
      setWasDirty && setWasDirty(true);
    };
    const onFailure = (message: string | undefined) => {
      setLoading(false);
      setError(message || networkError);
    };
    setLoading(true);
    const request = {
      firstName: firstName,
      lastName: lastName,
      isActive: isActive,
    };
    await updateUser(token, user.id, request, abortController.signal, onSuccess, onFailure);
  };

  return (
    <Box component={'form'} onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <ConfirmDialog open={showConfirm} setOpen={setShowConfirm} callback={update} />
        <Grid item xs={12} sm={4}>
          <TextField
            required={!locked}
            fullWidth
            id="firstName"
            name="firstName"
            autoComplete="given-name"
            autoFocus
            disabled={locked}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              setDirty(true);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required={!locked}
            fullWidth
            id="lastName"
            disabled={locked}
            name="lastName"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              setDirty(true);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField fullWidth id="email" name="email" type="email" disabled value={user.email} />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  disabled={locked || (!isAdminPage && !hasFlag(user.flags, UserFlags.Administrator))}
                  color={isActive ? 'success' : 'default'}
                  onClick={() => {
                    setIsActive((prev) => !prev);
                    setDirty(true);
                  }}
                />
              }
              label="Active"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={0} sm={7}></Grid>
        <Grid item xs={12} sm={5}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button disabled={locked} type="submit" variant="contained">
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserProfile;
