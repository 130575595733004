import { baseURL } from '../base';
import logger from '../../shared/logging/logger';
import { networkError } from '../base';
const controller = `${baseURL}/payors`;

export const getPayors = async (token, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal: signal,
    });
    logger.debug(response);
    if (response.ok) {
      const { success, data } = await response.json();
      if (success) {
        onSuccess && onSuccess(data);
      }
    } else {
      onFailure && onFailure(networkError);
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(networkError);
    }
  }
};

export const addPayor = async (token, request, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}/payor`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal: signal,
      body: JSON.stringify(request),
    });
    logger.debug(response);
    if (response.ok) {
      const { success, data } = await response.json();
      if (success) {
        onSuccess && onSuccess(data);
      }
    } else {
      onFailure && onFailure(networkError);
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(networkError);
    }
  }
};

export const updatePayor = async (token, id, request, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}/payor/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal: signal,
      body: JSON.stringify(request),
    });
    logger.debug(response);
    if (response.ok) {
      const { success, data } = await response.json();
      if (success) {
        onSuccess && onSuccess(data);
      }
    } else {
      onFailure && onFailure(networkError);
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(networkError);
    }
  }
};

export const getCategories = async (token, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}/categories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal: signal,
    });
    logger.debug(response);
    if (response.ok) {
      const { success, data } = await response.json();
      if (success) {
        onSuccess && onSuccess(data);
      }
    } else {
      onFailure && onFailure(networkError);
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(networkError);
    }
  }
};

export const addCategory = async (token, request, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}/categories/category`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal: signal,
      body: JSON.stringify(request),
    });
    logger.debug(response);
    if (response.ok) {
      const { success, data } = await response.json();
      if (success) {
        onSuccess && onSuccess(data);
      }
    } else {
      onFailure && onFailure(networkError);
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(networkError);
    }
  }
};

export const updateCategory = async (token, id, request, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}/categories/category/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal: signal,
      body: JSON.stringify(request),
    });
    logger.debug(response);
    if (response.ok) {
      const { success, data } = await response.json();
      if (success) {
        onSuccess && onSuccess(data);
      }
    } else {
      onFailure && onFailure(networkError);
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(networkError);
    }
  }
};
