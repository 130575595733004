import React, { FC, forwardRef } from 'react';
import { useSession } from '../hooks/useSession';
import Avatar from '@mui/material/Avatar';
import User from '../../shared/types/user';
import { SxProps } from '@mui/material/styles';

const getInitials = (u: User) => u.firstName.charAt(0).concat(u.lastName.charAt(0));

interface Props extends React.ComponentProps<typeof Avatar> {
  sx?: SxProps;
}

const UserAvatar = forwardRef<HTMLDivElement, Props>(({ sx, ...rest }, ref) => {
  const { user } = useSession();

  return (
    <Avatar
      ref={ref}
      sx={{
        bgcolor: '#1f6cfa',
        ...sx,
      }}
      {...rest}
    >
      {getInitials(user)}
    </Avatar>
  );
});

UserAvatar.displayName = 'UserAvatar';
export default UserAvatar;
