import { useState, FC } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import AppProps from '../../shared/types/appProps';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import Users from './users/Users';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import Collapse from '@mui/material/Collapse';
import PaidIcon from '@mui/icons-material/Paid';
import Payors from './payors/Payors';
import Categories from './payors/Categories';
import CategoryIcon from '@mui/icons-material/Category';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const drawerWidth = 240;

enum AdminPage {
  Users,
  Settings,
}

enum Settings {
  PayorCategories,
  Payors,
}

const Admin: FC<AppProps> = (props) => {
  const [selectedPage, setSelectedPage] = useState<AdminPage>(AdminPage.Users);
  const [selectedSetting, setSelectedSetting] = useState<Settings | undefined>();

  const setPage = (page: AdminPage, setting?: Settings | undefined) => {
    setSelectedPage(page);
    setSelectedSetting(setting);
  };

  return (
    <Grid container minHeight={'93vh'}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            zIndex: 1200,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <List>
          <ListItem disablePadding>
            <ListItemButton selected={selectedPage === AdminPage.Users} onClick={() => setPage(AdminPage.Users)}>
              <ListItemIcon>
                <PersonIcon color={selectedPage === AdminPage.Users ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              selected={selectedPage === AdminPage.Settings}
              onClick={() => setPage(AdminPage.Settings, Settings.PayorCategories)}
            >
              <ListItemIcon>
                <SettingsIcon color={selectedPage === AdminPage.Settings ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
          <Collapse in={selectedPage === AdminPage.Settings} timeout="auto">
            <List disablePadding>
              <ListItem sx={{ pl: 4 }}>
                <ListItemButton
                  selected={selectedSetting === Settings.PayorCategories}
                  onClick={() => setSelectedSetting(Settings.PayorCategories)}
                  dense={true}
                >
                  <ListItemIcon>
                    <CategoryIcon color={selectedSetting === Settings.PayorCategories ? 'primary' : 'inherit'} />
                  </ListItemIcon>
                  <ListItemText primary="Payor Categories" />
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 4 }}>
                <ListItemButton
                  selected={selectedSetting === Settings.Payors}
                  onClick={() => setSelectedSetting(Settings.Payors)}
                  dense={true}
                >
                  <ListItemIcon>
                    <PaidIcon color={selectedSetting === Settings.Payors ? 'primary' : 'inherit'} />
                  </ListItemIcon>
                  <ListItemText primary="Payors" />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
      <Paper
        elevation={3}
        sx={{
          padding: '20px 50px 60px 50px',
          borderRadius: '20px',
          position: 'relative',
          width: 'calc(100vw - 240px)',
          marginLeft: '240px',
          minHeight: 'calc(100vh - 82px)',
        }}
      >
        {selectedPage === AdminPage.Users && (
          <Box>
            <Users {...props} />
          </Box>
        )}
        {selectedSetting === Settings.PayorCategories && (
          <Box>
            <Categories {...props} />
          </Box>
        )}
        {selectedSetting === Settings.Payors && (
          <Box>
            <Payors {...props} />
          </Box>
        )}
      </Paper>
    </Grid>
  );
};

export default Admin;
