import { baseURL, networkError } from '../base';
import logger from '../../shared/logging/logger';
const controller = `${baseURL}/auth`;

export const login = async (email, password, signal, onSuccess, onFailure) => {
  const payload = {
    email,
    password,
  };
  try {
    const response = await fetch(`${controller}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      signal: signal,
    });
    const { success, data, message } = await response.json();
    logger.debug(response);
    if (response.ok && success) {
      const { token, user } = data;
      onSuccess && onSuccess(token, user);
    } else {
      onFailure && onFailure(message || 'Error while attempting to sign in');
    }
  } catch (error) {
    logger.error(error);
    onFailure && onFailure('Error while attempting to sign in');
  }
};

export const signUp = async (token, firstName, lastName, email, password, onSuccess, onFailure) => {
  const payload = {
    firstName,
    lastName,
    email,
    password,
  };
  try {
    const response = await fetch(`${controller}/sign-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const { success, message, data } = await response.json();
    logger.debug(response);
    if (response.ok && success) {
      onSuccess && onSuccess(data);
    } else {
      onFailure && onFailure(message || 'Error while attempting to sign up');
    }
  } catch (error) {
    logger.error(error);
    onFailure && onFailure('Error while attempting to sign up');
  }
};

export const confirmSignUp = async (email, confirmationCode, signal, onSuccess, onFailure) => {
  const payload = {
    email,
    confirmationCode,
  };

  try {
    const response = await fetch(`${controller}/confirm-sign-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      signal: signal,
    });
    const { success, message } = await response.json();
    logger.debug(response);
    if (response.ok && success) {
      onSuccess && onSuccess();
    } else {
      onFailure && onFailure(message || 'Error while confirming sign up');
    }
  } catch (error) {
    logger.error(error);
    onFailure && onFailure('Error while confirming sign up');
  }
};

export const refreshToken = async (userId, token, onSuccess, onFailure) => {
  const payload = {
    userId,
    token,
  };
  try {
    const response = await fetch(`${controller}/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const { success, data } = await response.json().catch(() => {
      throw new Error('Error while attempting to refresh token. Logging out.');
    });

    if (!success) {
      throw new Error('Token refresh was unsuccessful. Logging out.');
    }
    const token = { data };
    onSuccess && onSuccess(token);
    logger.info('Continuing Session');
  } catch (error) {
    logger.error(error);
    onFailure();
  }
};

export const adminChangePassword = async (userId, password, token, signal, onSuccess, onFailure) => {
  const payload = {
    userId,
    password,
  };

  try {
    const response = await fetch(`${controller}/admin-change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const { success, message } = await response.json().catch((e) => {
      throw new Error(e);
    });

    if (!success) {
      onFailure && onFailure(message || networkError);
    } else {
      onSuccess && onSuccess('User password has been changed!.');
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure &&
        onFailure('Error encountered while attempting to change password. Please try again or contact support.');
    }
  }
};

export const changePassword = async (userId, password, proposedPassword, token, signal, onSuccess, onFailure) => {
  const payload = {
    userId,
    password,
    proposedPassword,
  };

  try {
    const response = await fetch(`${controller}/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const { success, message } = await response.json().catch((e) => {
      throw new Error(e);
    });
    if (!success) {
      onFailure && onFailure(message || networkError);
    } else {
      onSuccess && onSuccess('User password has been changed!.');
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure &&
        onFailure('Error encountered while attempting to change password. Please try again or contact support.');
    }
  }
};
