import SocketProps from '../socketProps';
import Patient from '../../../shared/types/patient';
import SocketResponse from '../socketResponse';
import logger from '../../../shared/logging/logger';
import { networkError } from '../../base';
import PatientMessage from '../../../shared/types/patientMessage';
import WorkflowResultSummary from '../../../shared/types/workflowResultSummary';

export const handleGetReferrals = (response: SocketResponse<Patient[]>, props: SocketProps): void => {
  const { onSuccess, onFailure } = props;

  if (response.isError) {
    onFailure && onFailure(response.message);
  }
  onSuccess && onSuccess(response.data);
};

export const handleAuthResponse = (response: SocketResponse<object>, props: SocketProps, callback: () => void) => {
  if (response.isError) {
    logger.error(response.message || 'Error authorizing socket.');
    props.notify('Error', 'Error authorizing socket. Logging out.', 'error');
    props.logout();
  }
  callback && callback();
  logger.debug('Socket authorized');
};

export const handleMessageUpdateResponse = (response: SocketResponse<PatientMessage>, props: SocketProps) => {
  if (response.isError) {
    props.onMessageUpdateFailure && props.onMessageUpdateFailure(response.message || networkError);
  } else {
    props.onMessageUpdateSuccess && response.data && props.onMessageUpdateSuccess(response.data);
  }
};

export const handleUpdateSummaries = (response: SocketResponse<WorkflowResultSummary[]>, props: SocketProps) => {
  if (response.isError) {
    props.onSummaryUpdateFailure && props.onSummaryUpdateFailure(response.message || networkError);
  } else {
    props.onSummaryUpdateSuccess && response.data && props.onSummaryUpdateSuccess(response.data);
  }
};
