import React, { useState, FC } from 'react';
import User from '../../shared/types/user';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useSession } from '../hooks/useSession';
import { networkError } from '../../api/base';
import Box from '@mui/material/Box/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { updatePermissions } from '../../api/users/requests';
import List from '@mui/material/List';
import { PermissionGroups as PermissionGroup, getPermissions } from '../../shared/types/permissions';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

interface Props {
  user: User;
  locked: boolean;
  showConfirm: boolean;
  setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<string>;
  setSuccessMessage: React.Dispatch<string>;
}

const sortPermissions = (x: PermissionGroup, y: PermissionGroup) => {
  const [permA_1, permA_2] = PermissionGroup[x].split('_');
  const [permB_1, permB_2] = PermissionGroup[y].split('_');

  if (permA_2 && permB_2 && permA_2.localeCompare(permB_2) === 0) {
    // Compare first parts (e.g., "Edit" vs "View")
    return permA_1.localeCompare(permB_1);
  }
  if (!permA_2 && permB_2) {
    return -1;
  }
  if (permA_2 && !permB_2) {
    return 1;
  }

  // Compare the second parts if both exist
  if (permA_2 && permB_2) {
    return permA_2.localeCompare(permB_2);
  }
  return permA_1.localeCompare(permB_1);
};

const Permissions: FC<Props> = (props) => {
  const { locked, showConfirm, setShowConfirm, setSuccessMessage, user, setError } = props;
  const { token } = useSession();
  const [checked, setChecked] = useState(new Set<PermissionGroup>([...user.permissionGroups]));
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  // Events
  const handleToggle = (group: PermissionGroup) => () => {
    const newChecked = new Set(checked);

    if (newChecked.has(group)) {
      newChecked.delete(group);
    } else {
      newChecked.add(group);
    }
    setDirty(true);
    setChecked(newChecked);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    if (dirty) {
      setShowConfirm(true);
    }
  };

  const update = async () => {
    const abortController = new AbortController();

    const onSuccess = (data: PermissionGroup[]) => {
      setLoading(false);
      setChecked(new Set(data));
      setSuccessMessage('Changes saved!');
      setDirty(false);
    };

    const onFailure = (message: string | undefined | Error) => {
      setLoading(false);
      setError(message?.toString() ?? networkError);
    };
    setLoading(true);
    const request = {
      groups: [...checked],
    };
    await updatePermissions(token, user.id, request, abortController.signal, onSuccess, onFailure);
  };

  return (
    <Box component={'form'} onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <ConfirmDialog open={showConfirm} setOpen={setShowConfirm} callback={update} />
        <Grid item xs={12} sm={8}>
          <Box sx={{ width: '35vw' }} />
        </Grid>
        <FormGroup>
          <List sx={{ width: '35vw' }}>
            {[...getPermissions()].sort(sortPermissions).map((group) => {
              const isChecked = checked.has(group);
              const groupName = PermissionGroup[group];
              const groupParts = groupName.split('_');
              // If the group has 2 parts, it controls specific page permissions.
              const pagePermission = groupParts.length > 1;
              const labelId = `checkbox-list-label-${groupParts.join('-')}`;

              return (
                <ListItem disableGutters key={group}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ListItemButton
                        role="checkbox"
                        aria-checked={isChecked}
                        disabled={locked}
                        onClick={handleToggle(group)}
                        sx={{ width: '100%' }}
                      >
                        <ListItemText
                          id={labelId}
                          primaryTypographyProps={{ fontSize: 18, fontWeight: 'medium' }}
                          primary={pagePermission ? groupParts[1] : groupParts[0]}
                          sx={{ textAlign: 'left' }}
                        />
                        <ListItemIcon>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isChecked}
                                inputProps={{ 'aria-labelledby': labelId }}
                                id={`${group}-check`}
                              />
                            }
                            label={pagePermission ? groupParts[0] : ''}
                            labelPlacement="top"
                          />
                        </ListItemIcon>
                      </ListItemButton>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </List>

          {/* Spacer grid item to center the content */}
          <Grid container justifyContent="flex-end" alignItems="center" sx={{ marginTop: 2 }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button disabled={locked} type="submit" variant="contained">
                Save
              </Button>
            )}
          </Grid>
        </FormGroup>
      </Grid>
    </Box>
  );
};

export default Permissions;
