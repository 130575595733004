import React, { useState, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useSession } from '../../../components/hooks/useSession';
import { networkError } from '../../../api/base';
import Box from '@mui/material/Box/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton/IconButton';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import Payor from '../../../shared/types/payor';
import { addPayor, getCategories, updatePayor } from '../../../api/payors/requests';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PayorCategory from '../../../shared/types/payorCategory';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

interface Props {
  setPayors: React.Dispatch<Payor[] | undefined>;
  notify: (title: string, message: string, variant: 'success' | 'error' | 'warning' | 'info') => void;
  setPayor: React.Dispatch<Payor | undefined>;
  payor: Payor;
}

const abortController = new AbortController();

const PayorComponent: FC<Props> = (props) => {
  const { setPayors, setPayor, payor, notify } = props;
  const { token } = useSession();

  const [locked, setLocked] = useState(payor.id > 0);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [name, setName] = useState<string>(payor.name);
  const [category, setCategory] = useState<number>(payor.categoryId ?? -1);
  const [categories, setCategories] = useState<PayorCategory[] | undefined>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [wasDirty, setWasDirty] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [isActive, setIsActive] = useState<boolean>(payor.isActive);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        await getCategories(
          token,
          abortController.signal,
          (data: PayorCategory[]) => setCategories(data),
          (message: string | undefined) => notify('Error', message || networkError, 'error')
        );
      } finally {
        setPageLoading(false);
      }
    };
    fetchData();
    return () => abortController.abort();
  }, [token, notify]);

  // Events
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (dirty) {
      setShowConfirm(true);
    } else {
      setPayor(undefined);
    }
  };

  const handleToggle = () => {
    setLocked(!locked);
  };

  const handleIconClick = () => {
    wasDirty && setPayors(undefined);
    setPayor(undefined);
  };

  const update = async () => {
    const onSuccess = (payor: Payor) => {
      setLoading(false);
      setPayor(payor);
      setCategory(payor.categoryId ?? -1);
      setSuccessMessage('Changes saved!');
      setDirty(false);
      setWasDirty(true);
    };
    const onFailure = (message: string | undefined) => {
      setLoading(false);
      setError(message || networkError);
    };
    setLoading(true);
    const request = {
      name: name,
      isActive: isActive,
      categoryId: category > 0 ? category : null,
    };
    if (payor.id > 0) {
      await updatePayor(token, payor.id, request, abortController.signal, onSuccess, onFailure);
    } else {
      await addPayor(token, request, abortController.signal, onSuccess, onFailure);
    }
  };

  if (pageLoading) {
    return (
      <Box
        sx={{ height: '86vh', width: '80vw' }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box component={'form'} onSubmit={handleSubmit}>
      <ConfirmDialog open={showConfirm} setOpen={setShowConfirm} callback={update} />
      <IconButton aria-label="Back" onClick={() => handleIconClick()}>
        <ArrowBackIcon fontSize="large" />
      </IconButton>
      <Box
        sx={{
          maxWidth: '80vw',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: '52px',
        }}
      >
        <Grid container width={'20rem'}></Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <IconButton
              aria-label="Back"
              onClick={handleToggle}
              style={{ cursor: 'pointer', color: locked ? 'red' : 'green' }}
            >
              {locked ? <LockPersonIcon fontSize="large" /> : <LockOpenIcon fontSize="large" />}
            </IconButton>
            <Typography fontSize={'large'}>{payor.id > 0 ? payor.name : 'New Payor'}</Typography>
            {Boolean(error) && (
              <Typography variant="subtitle2" color="error" align="left" sx={{ width: '100%' }}>
                {error}
              </Typography>
            )}
            {Boolean(successMessage) && (
              <Typography variant="subtitle2" color="green" align="left" sx={{ width: '100%' }}>
                {successMessage}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required={!locked}
              fullWidth
              id="name"
              name="name"
              label="Name"
              autoFocus
              disabled={locked || loading}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setDirty(true);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                id="demo-simple-select-helper"
                value={`${category}`}
                label="Category"
                disabled={locked || loading}
                onChange={(e) => {
                  setCategory(parseInt(e.target.value));
                  setDirty(true);
                }}
              >
                <MenuItem value="-1" key={-1}>
                  <em>None</em>
                </MenuItem>
                {categories &&
                  categories.map((x, i) => (
                    <MenuItem value={x.id} key={i}>
                      {x.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    disabled={locked || loading}
                    color={isActive ? 'success' : 'default'}
                    onClick={() => {
                      setIsActive((prev) => !prev);
                      setDirty(true);
                    }}
                  />
                }
                label="Active"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={0} sm={7}></Grid>
          <Grid item xs={12} sm={5}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button disabled={locked} type="submit" variant="contained">
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default PayorComponent;
