import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React, { useState, useEffect, FC, ChangeEvent } from 'react';
import useDebounce from '../hooks/useDebounce';

interface Props extends Omit<TextFieldProps, 'onChange'> {
  delay?: number;
  onChange: FunctionStringCallback;
}

const SearchField: FC<Props> = ({ delay = 500, onChange, ...props }) => {
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, delay);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      type="search"
      variant="outlined"
      InputProps={{
        ...props.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
