import React, { useState } from 'react'; // Added useState import
import IconButton from '@mui/material/IconButton';
import Info from '@mui/icons-material/Info';
import Schedule from '../../shared/types/schedule';
import WorkflowStatus from '../../shared/enums/workflowStatus';

interface Props {
  count: number;
  data: Schedule[];
  status: WorkflowStatus;
  onRowExpand: (id: number, count: number, show: boolean) => void;
  id: number;
}

const getCaregiversList = (schedules: Schedule[], status: WorkflowStatus) => {
  return schedules
    .filter((x) => x.status === status)
    .map((x) => {
      return <li key={x.id}>{x.caregiver}</li>;
    });
};

const getStatus = (status: WorkflowStatus) => {
  switch (status) {
    case WorkflowStatus.Created:
      return 'created';
    case WorkflowStatus.Error: 
      return 'failed';
    case WorkflowStatus.Updated:
      return 'updated';
  }
};

const CaregiverList: React.FC<Props> = ({ data, status, count, onRowExpand, id }) => {
  const [show, setShow] = useState(false); 

  const handleClick = () => {
    onRowExpand(id, count, !show);
    setShow(!show);
  };

  return (
    <div style={{ fontSize: '.8rem' }}>
      Total records {getStatus(status)}: {count}
      <IconButton onClick={() => handleClick()}>
        <Info color={status === WorkflowStatus.Error ? 'error' : 'success'} fontSize="small" />
      </IconButton>
      <ul style={{ display: show ? 'block' : 'none' }}>
        {getCaregiversList(data, status)}
      </ul>
    </div>
  );
};



export default CaregiverList;
