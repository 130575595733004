import { FC, useCallback, useState } from 'react';
import { DataGrid, GridRowHeightParams } from '@mui/x-data-grid';
import { columns } from './tableRenders';
import GridToolbar from '../toolbars/GridToolbar';
import WorkflowResult from '../../shared/types/workflowResult';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Schedule from '../../shared/types/schedule';

interface Props {
  data: WorkflowResult<Schedule[]>[];
  loading: boolean;
}

const HistoryTable: FC<Props> = ({ data, loading }) => {
  const theme = useTheme();
  const rows = useCallback(() => data, [data]);
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: number }>({});

  const handleRowExpand = (id: number, count: number, show: boolean) => {
    setExpandedRows((prev) => {
      const newExpandedRows = { ...prev };
      if (show) {
        newExpandedRows[id] = (newExpandedRows[id] || 0) + count;
      } else {
        newExpandedRows[id] = (newExpandedRows[id] || 0) - count;
        if (newExpandedRows[id] <= 0) {
          delete newExpandedRows[id];
        }
      }
      return newExpandedRows;
    });
  };

  const getRowHeight = (params: GridRowHeightParams) => {
    const baseHeight = 120;
    const increment = 20;
    const paddingBottom = 8;
    const expandedCount = expandedRows[params.id as number] || 0; 
    return baseHeight + increment * expandedCount + paddingBottom;
  };

  return (
    <Box width={'100%'} height={'85vh'}>
      <DataGrid
        rows={rows()}
        columns={columns(theme, handleRowExpand)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        getRowHeight={getRowHeight}
        sortingOrder={['desc', 'asc']}
        pagination
        loading={loading}
        rowHeight={100}
        pageSizeOptions={[10, 25, 50, 100]}
        slots={{ toolbar: GridToolbar }}
        disableRowSelectionOnClick
        slotProps={{
          toolbar: {
            sx: {
              paddingBottom: '1rem',
            },
          },
        }}
      />
    </Box>
  );
};

export default HistoryTable;
