// logger.js
const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

const logger = {
  debug: (...args) => {
    if (!isProduction) {
      console.debug('[DEBUG]', ...args);
    }
  },
  info: (...args) => {
    console.log('[INFO]', ...args);
  },
  error: (...args) => {
    console.error('[ERROR]', ...args);
  }
};

export default logger;