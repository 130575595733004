import React, { useState, FC, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import User from '../../../shared/types/user';
import Grid from '@mui/material/Grid';
import { useSession } from '../../../components/hooks/useSession';
import Box from '@mui/material/Box/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton/IconButton';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { getUser } from '../../../api/users/requests';
import UserProfile from '../../../components/users/UserProfile';
import UserSecurity from '../../../components/users/UserSecurity';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SecurityIcon from '@mui/icons-material/Security';
import Toolbar from '@mui/material/Toolbar';
import { SecuritySetting } from '../../../shared/types/permissions';
import { Collapse } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Loader from '../../../components/loader/Loader';

interface Props {
  setUsers?: React.Dispatch<User[] | undefined>;
  notify: (title: string, message: string, variant: 'success' | 'error' | 'warning' | 'info') => void;
  userId: number;
  setUserId?: React.Dispatch<number>;
  isAdminPage: boolean;
}

enum Page {
  Personal,
  Security,
}

const UserComponent: FC<Props> = (props) => {
  const { setUsers, userId, setUserId, notify, isAdminPage } = props;
  const [user, setUser] = useState<User>();
  const { token } = useSession();

  const [locked, setLocked] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [wasDirty, setWasDirty] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [selectedPage, setSelectedPage] = useState<Page>(Page.Personal);
  const [selectedSetting, setSelectedSetting] = useState<SecuritySetting | undefined>();

  useEffect(() => {
    const abortController = new AbortController();
    if (userId && !user) {
      getUser(
        token,
        userId,
        abortController.signal,
        (data: User) => {
          setUser(data);
          setLoading(false);
        },
        (message: string) => {
          notify('Error', message, 'error');
          setLoading(false);
        }
      );
    }
  }, [userId, token, user, notify]);

  const handleToggle = () => {
    setLocked(!locked);
  };

  const handleIconClick = () => {
    setUserId && setUserId(0);
    wasDirty && setUsers && setUsers(undefined);
  };

  const settingProps = {
    locked,
    dirty,
    setDirty,
    showConfirm,
    setShowConfirm,
    setUser,
    setUserId,
    setWasDirty,
    setError,
    setSuccessMessage,
    isAdminPage: isAdminPage,
    selectedSetting,
  };

  const setPage = (setting: Page, securitySetting?: SecuritySetting | undefined) => {
    setSelectedPage(setting);
    setSelectedSetting(securitySetting);
  };

  return (
    <Grid item xs={12}>
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            zIndex: 1300,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <List>
          <ListItem disablePadding>
            <ListItemButton selected={selectedPage === Page.Personal} onClick={() => setPage(Page.Personal)}>
              <ListItemIcon>
                <AccountCircleIcon color={selectedPage === Page.Personal ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText primary="Personal" />
            </ListItemButton>
          </ListItem>
          <List>
            <ListItem>
              <ListItemButton
                selected={selectedPage === Page.Security}
                onClick={() => setPage(Page.Security, SecuritySetting.Password)}
              >
                <ListItemIcon>
                  <SecurityIcon color={selectedPage === Page.Security ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Security" />
              </ListItemButton>
            </ListItem>
            <Collapse in={selectedPage === Page.Security} timeout="auto">
              <List disablePadding>
                <ListItem sx={{ pl: 4 }}>
                  <ListItemButton
                    selected={selectedSetting === SecuritySetting.Password}
                    onClick={() => setPage(Page.Security, SecuritySetting.Password)}
                  >
                    <ListItemIcon>
                      <PasswordIcon color={selectedSetting === SecuritySetting.Password ? 'primary' : 'inherit'} />
                    </ListItemIcon>
                    <ListItemText primary="Password" />
                  </ListItemButton>
                </ListItem>
                {isAdminPage && (
                  <ListItem sx={{ pl: 4 }}>
                    <ListItemButton
                      selected={selectedSetting === SecuritySetting.Permissions}
                      onClick={() => setPage(Page.Security, SecuritySetting.Permissions)}
                    >
                      <ListItemIcon>
                        <AdminPanelSettingsIcon
                          color={selectedSetting === SecuritySetting.Permissions ? 'primary' : 'inherit'}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Permissions" />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </List>
        </List>
      </Drawer>
      {isAdminPage && (
        <IconButton aria-label="Back" onClick={() => handleIconClick()}>
          <ArrowBackIcon fontSize="large" />
        </IconButton>
      )}
      <Box
        sx={{
          maxWidth: '80vw',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: '52px',
          height: '95%',
        }}
      >
        <Grid container width={'20rem'}></Grid>
        <Grid container spacing={3}>
          <Loader loading={loading} />
          <Grid item xs={12}>
            <IconButton
              aria-label="Locked"
              onClick={handleToggle}
              style={{ cursor: 'pointer', color: locked ? 'red' : 'green' }}
            >
              {locked ? <LockPersonIcon fontSize="large" /> : <LockOpenIcon fontSize="large" />}
            </IconButton>
            {user && <Typography fontSize={'large'}>{`${user.lastName}, ${user.firstName}`}</Typography>}
            {Boolean(error) && (
              <Typography variant="subtitle2" color="error" align="left" sx={{ width: '100%' }}>
                {error}
              </Typography>
            )}
            {Boolean(successMessage) && (
              <Typography variant="subtitle2" color="green" align="left" sx={{ width: '100%' }}>
                {successMessage}
              </Typography>
            )}
          </Grid>
          {selectedPage === Page.Personal && user && <UserProfile {...settingProps} user={user} />}
          {selectedPage === Page.Security && user && (
            <UserSecurity {...settingProps} user={user} selectedSetting={selectedSetting} />
          )}
        </Grid>
      </Box>
    </Grid>
  );
};
export default UserComponent;
