import { GridColDef, GridRenderCellParams, GridValueGetter } from '@mui/x-data-grid';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import Payor from '../../shared/types/payor';

export interface DataTableProps {
  data: Payor[] | undefined;
  loading: boolean;
  setPayor: React.Dispatch<Payor | undefined>;
}

export const columns: GridColDef<Payor>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'isActive',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<Payor, string>) => {
      return (
        <span style={{ color: params.row.isActive ? green[500] : red[500] }}>
          {params.row.isActive ? 'Active' : 'Inactive'}
        </span>
      );
    },
  },
  {
    field: 'category',
    headerName: 'Category',
    minWidth: 100,
    flex: 1,
    renderCell: (params: GridRenderCellParams<Payor, string>) => {
      return <span>{params.row.category?.name || ''}</span>;
    },
  },
];
