import React, { FC, useEffect, useState } from 'react';
import WorkflowResultSummary from '../../shared/types/workflowResultSummary';
import { getLatestReferralWorkflowResults } from '../../api/workflows/requests';
import { useSession } from '../hooks/useSession';
import { networkError } from '../../api/base';
import Box from '@mui/material/Box/Box';
import List from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { utcToLocal } from '../../shared/dates/date';
import Typography from '@mui/material/Typography/Typography';
import UserSocket from '../../shared/types/userSocket';
import logger from '../../shared/logging/logger';
import { trackSummaries } from '../../api/sockets/user/userRequests';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  notify: (title: string, message: string, variant: 'success' | 'error' | 'warning' | 'info') => void;
  userSocket: UserSocket;
  data: WorkflowResultSummary[] | undefined;
  setData: React.Dispatch<React.SetStateAction<WorkflowResultSummary[] | undefined>>;
}

const format = (result: WorkflowResultSummary) => {
  return `${result.healthNetwork} ${utcToLocal(new Date(result.lastRun))}`;
};

const ResultSummary: FC<Props> = (props: Props) => {
  const { notify, data, setData, userSocket } = props;
  const { token } = useSession();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userSocket.props.onSummaryUpdateSuccess = (data: WorkflowResultSummary[]) => {
      setData((prevData) => {
        const dataMap = new Map(prevData?.map((item) => [item.healthNetworkId, item]));
        data.forEach((update) => {
          const existingItem = dataMap.get(update.healthNetworkId);
          if (existingItem) {
            existingItem.lastRun = update.lastRun;
            existingItem.success = update.success;
          } else {
            dataMap.set(update.healthNetworkId, update);
          }
        });
        return Array.from(dataMap.values());
      });
    };
    userSocket.props.onSummaryUpdateFailure = (message) => {
      logger.error(message || 'Error updating Summaries');
    };
  }, [userSocket.props, setData]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      await getLatestReferralWorkflowResults(
        token,
        abortController.signal,
        (data: WorkflowResultSummary[]) => {
          setData(data);
          setLoading(false);
          if (userSocket?.socket) {
            userSocket.socket.send(JSON.stringify(trackSummaries(data)));
          }
        },
        (message: string | undefined) => {
          notify('Error', message || networkError, 'error');
          setLoading(false);
        }
      );
    };
    !data && fetchData();
    return () => abortController.abort();
  }, [notify, setData, data, setLoading, token, userSocket.socket]);

  return (
    <Box sx={{ paddingTop: '1rem', paddingBottom: data?.length ? '0' : '5rem' }}>
      <Typography fontSize={'small'}>System Status {loading && <CircularProgress size={10} />}</Typography>

      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))', gap: 0 }}>
        {data &&
          data.map((x) => (
            <Box
              key={x.healthNetworkId}
              sx={{ display: 'grid', gridTemplateRows: 'repeat(2, auto)', gap: 0, padding: 0 }}
            >
              <List dense disablePadding>
                <ListItem alignItems="flex-start">
                  <ListItemIcon sx={{ minWidth: 'auto' }}>
                    {x.success ? (
                      <Tooltip title="System Online">
                        <CheckCircleIcon color="success" fontSize="small" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="System Offline!">
                        <ErrorIcon color="error" fontSize="small" />
                      </Tooltip>
                    )}
                  </ListItemIcon>
                  <Fade in>
                    <ListItemText
                      primary={format(x)}
                      secondary={x.success ? 'System Online' : 'System Offline!'}
                      primaryTypographyProps={{
                        fontSize: '12px',
                      }}
                      secondaryTypographyProps={{
                        fontSize: '10px',
                        color: x.success ? 'primary' : 'error',
                      }}
                    />
                  </Fade>
                </ListItem>
              </List>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default ResultSummary;
