import { networkError } from '../base';
import { baseURL } from '../base';
import logger from '../../shared/logging/logger';
const controller = `${baseURL}/referrals`;

export const getReferrals = async (token, userId, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}?userId=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal: signal,
    });
    if (response.ok) {
      const { success, data } = await response.json();
      if (success) {
        onSuccess && onSuccess(data);
      }
    } else {
      onFailure && onFailure(networkError);
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(error);
    }
  }
};
