import React, { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';

interface Props {
  loading: boolean;
}

const Loader: FC<Props> = ({ loading }) => {
  const [visible, setVisible] = useState(loading);

  useEffect(() => {
    if (loading) {
      setVisible(true);
    } else {
      // Add a small delay before hiding the loader to allow for fade-out effect
      const timer = setTimeout(() => setVisible(false), 500);
      return () => clearTimeout(timer); // Clear timeout on component unmount
    }
  }, [loading]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '35%',
        left: '50%',
        opacity: loading ? 1 : 0,
        transition: 'opacity 0.5s ease',
        visibility: visible ? 'visible' : 'hidden',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
