import React, { useState, FC } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useSession } from '../../../components/hooks/useSession';
import { networkError } from '../../../api/base';
import Box from '@mui/material/Box/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton/IconButton';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { addCategory, updateCategory } from '../../../api/payors/requests';
import PayorCategory from '../../../shared/types/payorCategory';
import ColorPicker from '../../../components/inputs/ColorPicker';

interface Props {
  setCategories: React.Dispatch<PayorCategory[] | undefined>;
  notify: (title: string, message: string, variant: 'success' | 'error' | 'warning' | 'info') => void;
  setCategory: React.Dispatch<PayorCategory | undefined>;
  category: PayorCategory;
}

const abortController = new AbortController();

const Category: FC<Props> = (props) => {
  const { setCategories, setCategory, category } = props;
  const { token } = useSession();

  const [locked, setLocked] = useState(category.id > 0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [name, setName] = useState<string>(category.name);
  const [color, setColor] = useState<string>(category.color);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [wasDirty, setWasDirty] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>();

  // Events
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (dirty) {
      setShowConfirm(true);
    } else {
      setCategory(undefined);
    }
  };

  const update = async () => {
    const onSuccess = (category: PayorCategory) => {
      setLoading(false);
      setCategory(category);
      setSuccessMessage('Changes saved!');
      setDirty(false);
      setWasDirty(true);
    };
    const onFailure = (message: string | undefined) => {
      setLoading(false);
      setError(message || networkError);
    };
    setLoading(true);
    const request = {
      name: name,
      color: color,
    };
    if (category.id === 0) {
      await addCategory(token, request, abortController.signal, onSuccess, onFailure);
    } else {
      await updateCategory(token, category.id, request, abortController.signal, onSuccess, onFailure);
    }
  };

  const handleToggle = () => {
    setLocked(!locked);
  };

  const handleIconClick = () => {
    wasDirty && setCategories(undefined);
    setCategory(undefined);
  };

  return (
    <Box component={'form'} onSubmit={handleSubmit}>
      <ConfirmDialog open={showConfirm} setOpen={setShowConfirm} callback={update} />
      <IconButton aria-label="Back" onClick={() => handleIconClick()}>
        <ArrowBackIcon fontSize="large" />
      </IconButton>
      <Box
        sx={{
          maxWidth: '80vw',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: '52px',
        }}
      >
        <Grid container width={'20rem'}></Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <IconButton
              aria-label="Back"
              onClick={handleToggle}
              style={{ cursor: 'pointer', color: locked ? 'red' : 'green' }}
            >
              {locked ? <LockPersonIcon fontSize="large" /> : <LockOpenIcon fontSize="large" />}
            </IconButton>
            <Typography fontSize={'large'}>{category.id === 0 ? 'New Category' : category.name}</Typography>
            {Boolean(error) && (
              <Typography variant="subtitle2" color="error" align="left" sx={{ width: '100%' }}>
                {error}
              </Typography>
            )}
            {Boolean(successMessage) && (
              <Typography variant="subtitle2" color="green" align="left" sx={{ width: '100%' }}>
                {successMessage}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required={!locked}
              fullWidth
              id="name"
              name="name"
              autoFocus
              disabled={locked}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setDirty(true);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ColorPicker disabled={loading || locked} setColor={setColor} color={color} setDirty={setDirty} />
          </Grid>
          <Grid item xs={0} sm={7}></Grid>
          <Grid item xs={12} sm={5}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button disabled={locked} type="submit" variant="contained">
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default Category;
