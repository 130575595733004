import React from 'react';
import { Box, Typography, Container, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './home.css';

const listItems = [
  'Simplify your workflow with our automation tools',
  'Streamline operations',
  'Increase efficiency',
  'Achieve more with less effort',
];

const Home = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '32px', // Adjusted for Navbar height
      }}
    >
      <Box
        sx={{
          padding: '50px',
          marginTop: '10rem',
          backgroundColor: '#f9f9f9',
          boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          width: '100%',
          maxWidth: '1000px',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            animation: 'fadeIn 2s ease-in-out',
            '@keyframes fadeIn': {
              '0%': { opacity: 0 },
              '100%': { opacity: 1 },
            },
            letterSpacing: '.09em',
            marginTop: '3rem',
            fontSize: { xs: '1.2rem', md: '1.25rem', lg: '2rem' },
          }}
        >
          Welcome to Streamline Automate
        </Typography>

        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '3rem',
          }}
        >
          <List sx={{ width: '100%', maxWidth: '600px', margin: '0 auto 0 35%' }}>
            {listItems.map((item, index) => (
              <ListItem key={index} className="list-item">
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary={item}
                  primaryTypographyProps={{
                    variant: 'h6',
                    lineHeight: '3',
                    sx: {
                      fontSize: { xs: '.8rem', md: '.9rem', lg: '1.25rem' },
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
