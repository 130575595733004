import React, { useRef } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { ColorLens as ColorLensIcon } from '@mui/icons-material';

interface Props {
  color: string;
  setColor: React.Dispatch<string>;
  disabled: boolean;
  setDirty: React.Dispatch<boolean>;
}

const ColorPicker: React.FC<Props> = (props) => {
  const { color, setColor, disabled, setDirty } = props;
  const colorInputRef = useRef<HTMLInputElement>(null);

  const handleIconClick = () => {
    if (colorInputRef.current) {
      colorInputRef.current.click();
    }
  };

  return (
    <TextField
      required
      id="colorPicker"
      name="colorPicker"
      label="Pick a Color"
      fullWidth
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setColor(e.target.value.replace('#', ''));
        setDirty(true);
      }}
      value={`#${color}`}
      type="color"
      disabled={disabled}
      inputRef={colorInputRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="choose color" onClick={handleIconClick}>
              <ColorLensIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ColorPicker;
