import { GridColDef } from '@mui/x-data-grid';
import PayorCategory from '../../shared/types/payorCategory';

export interface DataTableProps {
  data: PayorCategory[] | undefined;
  loading: boolean;
  setCategory: React.Dispatch<PayorCategory | undefined>;
}

export const columns: GridColDef<PayorCategory>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'color',
    headerName: 'Color',
    minWidth: 100,
    flex: 1,
    renderCell: (params) => (
      <div
        style={{
          backgroundColor: `#${params.value}`,
          width: '100%',
          height: '100%',
        }}
      ></div>
    ),
  },
];
