import { jwtDecode } from 'jwt-decode';
import CognitoJwtPayload from '../types/cognitoJwtPayload';
import Permission from '../types/permissions';

export const passwordErrors = [
  { regex: /.*\d.*/, error: 'Password must contain at least one number.' },
  { regex: /.*[a-z].*/, error: 'Password must contain at least one lowercase letter.' },
  { regex: /.*[A-Z].*/, error: 'Password must contain at least one uppercase letter.' },
  { regex: /.*[!@#$%^&*(),.?":{}|<>].*/, error: 'Password must contain at least one special character.' },
  { regex: /.{8,}/, error: 'Password must be at least 8 characters long.' },
];

export const validatePassword = (password: string | undefined, confirmPassword: string | undefined) => {
  if (!passwordsMatch(password, confirmPassword)) {
    return {
      isValid: false,
      message: 'Passwords must match',
    };
  }
  const errors = passwordErrors.filter(({ regex }) => !regex.test(password || '')).map(({ error }) => error);
  return {
    isValid: errors.length === 0,
    message: errors.join(' '),
  };
};

export const passwordsMatch = (pass1: string | undefined, pass2: string | undefined): boolean => pass1 === pass2;

export const getPermissions = (token: string, onFailure: () => void): Permission => {
  try {
    const decodedToken = jwtDecode(token) as CognitoJwtPayload;
    const groups = decodedToken['cognito:groups'];
    if (!groups) {
      throw new Error('No permission groups defined');
    }
    return new Permission(groups);
  } catch (error) {
    console.error(error);
    onFailure();
    // Return a default
    return new Permission([]);
  }
};
