import { FC, useEffect, useState } from 'react';
import AppProps from '../../../shared/types/appProps';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { networkError } from '../../../api/base';
import { useSession } from '../../../components/hooks/useSession';
import { Button } from '@mui/material';
import PayorComponent from './Payor';
import PayorTable from '../../../components/payors/PayorTable';
import { getPayors } from '../../../api/payors/requests';
import Payor from '../../../shared/types/payor';

const Payors: FC<AppProps> = (props) => {
  const [payors, setPayors] = useState<Payor[] | undefined>(undefined);
  const [payor, setPayor] = useState<Payor | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const { notify } = props;
  const { token } = useSession();

  useEffect(() => {
    const abortController = new AbortController();
    if (!payors) {
      const onSuccess = (data: Payor[]) => {
        setLoading(false);
        setPayors(data);
      };
      const onFailure = (message: string | undefined) => {
        setLoading(false);
        notify('Error', message || networkError, 'error');
      };
      setLoading(true);
      getPayors(token, abortController.signal, onSuccess, onFailure);
    }
    return () => {
      abortController.abort();
    };
  }, [payors, token, notify]);

  return (
    <Grid
      item
      sx={{
        marginTop: '3rem',
      }}
    >
      {payor ? (
        <PayorComponent setPayors={setPayors} notify={notify} setPayor={setPayor} payor={payor} />
      ) : (
        <>
          <Box
            sx={{
              textAlign: 'right',
              paddingBottom: '1rem',
            }}
          >
            <Button variant={'contained'} onClick={() => setPayor(new Payor())}>
              Add
            </Button>
          </Box>
          <Box sx={{ height: '85.5vh', width: '80vw' }}>
            <PayorTable loading={loading} data={payors || []} setPayor={setPayor} />
          </Box>
        </>
      )}
    </Grid>
  );
};

export default Payors;
