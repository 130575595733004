import { baseWS } from '../../base';
import requestType from '../requestType';
import SocketProps from '../socketProps';
import SocketRequest from '../socketRequest';
import SocketResponse from '../socketResponse';
import {
  handleAuthResponse,
  handleGetReferrals,
  handleMessageUpdateResponse,
  handleUpdateSummaries,
} from './userResponses';
import logger from '../../../shared/logging/logger';
import Token from '../../../shared/types/token';
import { authRequest } from './userRequests';

const controller = `${baseWS}/userhandler`;

export const connectToUserWS = (props: SocketProps, token: Token | undefined, callback: () => void) => {
  if (!token) {
    return;
  }

  let { heartBeat } = props;
  const { setSocket } = props;
  const socket = new WebSocket(controller);

  props.socket = socket;
  socket.onopen = (_e) => {
    heartBeat = setInterval(() => {
      beatHeart(props);
    }, 30000);
    logger.debug('Authorizing socket');
    socket.send(JSON.stringify(authRequest(token)));
  };

  socket.onmessage = (e) => handleReceive(e, props, callback);

  socket.onclose = (e) => {
    logger.info('Socket Disconnected. Reconnecting.', e.reason);
    clearInterval(heartBeat);
    setTimeout(() => {
      setSocket(connectToUserWS(props, token, callback));
    }, 5000);
  };
  return { socket, props };
};

const handleReceive = (e: MessageEvent, props: SocketProps, callback: () => void) => {
  const { notify } = props;
  try {
    const response = JSON.parse(e.data) as SocketResponse<any>;
    switch (response.requestType) {
      case requestType.HeartBeat:
        break;
      case requestType.Auth:
        handleAuthResponse(response, props, callback);
        break;
      case requestType.GetReferrals:
        handleGetReferrals(response, { ...props });
        break;
      case requestType.UpdateMessage:
        handleMessageUpdateResponse(response, props);
        break;
      case requestType.TrackSummaries:
        // Nothing to do
        break;
      case requestType.UpdateSummaries:
        handleUpdateSummaries(response, props);
        break;
      default: {
        if (response.isError) {
          props.onFailure && props.onFailure(response.message);
        } else {
          props.onSuccess && props.onSuccess(response.data);
        }
      }
    }
  } catch (err) {
    logger.error(err);
    notify('Error', 'Error retrieving Data from Server.  Please contact MEDsys Support.', 'error');
  }
};

const beatHeart = (props: SocketProps) => {
  const { socket, heartBeat } = props;
  if (!socket || socket.readyState !== WebSocket.OPEN) {
    clearInterval(heartBeat);
  } else {
    const request: SocketRequest = {
      requestType: requestType.HeartBeat,
      data: {},
    };
    logger.debug('Heart beating');
    socket.send(JSON.stringify(request));
  }
};
