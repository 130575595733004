import React, { useState, useCallback, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/home/Home';
import Navbar from './components/navbar/Navbar';
import AppProps from './shared/types/appProps';
import Login from './pages/login/Login';
import { useSession } from './components/hooks/useSession';
import UserSocket from './shared/types/userSocket';
import SocketProps from './api/sockets/socketProps';
import { connectToUserWS } from './api/sockets/user/userSocket';
import Admin from './pages/admin/Admin';
import CheckSession from './components/dialogs/CheckSession';
import Account from './pages/account/Account';
import SomethingWentWrong from './pages/error/SomethingWentWrong';
import AccountVerified from './pages/account/AccountVerified';
import Referrals from './pages/referrals/Referrals';
import { Page } from './shared/types/permissions';
import Unauthorized from './pages/error/Unauthorized';
import Workflows from './pages/workflows/Workflows';

let sessionTimer: NodeJS.Timeout | undefined;

const App = () => {
  const [userSocket, setUserSocket] = useState<UserSocket | undefined>(undefined);
  const { token, setToken, user, permissions } = useSession();
  const [socketAuthorized, setSocketAuthorized] = useState<boolean>(false);
  const [checkSession, setCheckSession] = useState(false);

  const notify = useCallback((title: string, msg: string, variant: 'success' | 'error' | 'warning' | 'info') => {
    toast.dismiss();
    toast[variant](`${title}\r\n: ${msg}`);
  }, []);

  const appProps: AppProps = {
    notify: notify,
    userSocket: userSocket,
    socketAuthorized: socketAuthorized,
  };

  const logout = useCallback(() => {
    // Close Socket Connection
    if (userSocket?.socket) {
      userSocket.socket.onclose = null;
      userSocket.socket.close();
      userSocket.socket = undefined;
    }
    setSocketAuthorized(false);
    setUserSocket(undefined);
    setToken(undefined);
    clearTimeout(sessionTimer);
    sessionTimer = undefined;
  }, [setUserSocket, setToken, userSocket]);

  useEffect(() => {
    if (!userSocket && token) {
      const props: SocketProps = {
        setSocket: setUserSocket,
        notify: notify,
        logout: logout,
        onFailure: (message) => notify('Error', message || 'Network Error.', 'error'),
      };
      setUserSocket(connectToUserWS(props, token, () => setSocketAuthorized(true)));
    }
  }, [userSocket, setUserSocket, notify, token, setToken, logout, socketAuthorized]);

  useEffect(() => {
    if (token && !sessionTimer) {
      sessionTimer = setTimeout(() => setCheckSession(true), (token.expiresIn - 180) * 1000);
    }
    // Clean up the timer
    return () => {
      clearTimeout(sessionTimer);
      sessionTimer = undefined;
    };
  }, [token]);

  return (
    <>
      {token && <Navbar onLogout={logout} />}
      <Routes>
        {token && userSocket ? (
          <>
            <Route
              path="/home"
              element={!permissions.IsRestricted(Page.Home) ? <Home /> : <Navigate to="/unauthorized" />}
            />
            <Route
              path="/admin"
              element={
                !permissions.IsRestricted(Page.Administrator) ? (
                  <Admin {...appProps} />
                ) : (
                  <Navigate to="/unauthorized" />
                )
              }
            />
            <Route
              path="/account"
              element={
                !permissions.IsRestricted(Page.Account) ? (
                  <Account notify={notify} userId={user.id} />
                ) : (
                  <Navigate to="/unauthorized" />
                )
              }
            />
            <Route
              path="/referrals"
              element={
                !permissions.IsRestricted(Page.Referrals) ? (
                  <Referrals {...appProps} />
                ) : (
                  <Navigate to="/unauthorized" />
                )
              }
            />
            <Route
              path="/workflows"
              element={
                !permissions.IsRestricted(Page.Workflows) ? (
                  <Workflows {...appProps} />
                ) : (
                  <Navigate to="/unauthorized" />
                )
              }
            />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/error" element={<SomethingWentWrong />} />
            <Route path="/verified" element={<AccountVerified />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      {checkSession && (
        <CheckSession setCheckSession={setCheckSession} logout={logout} open={checkSession} notify={notify} />
      )}
    </>
  );
};

export default App;
