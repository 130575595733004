import { useCallback } from 'react';
import React, { createContext, useContext, useState, ReactNode, FC, ReactElement } from 'react';
import { login as apiLogin } from '../../api/auth/authRequests';
import Token from '../../shared/types/token';
import logger from '../../shared/logging/logger';
import User from '../../shared/types/user';
import { getPermissions } from '../../shared/security/security';
import Permission from '../../shared/types/permissions';

interface Session {
  token?: Token;
  setToken: React.Dispatch<Token | undefined>;
  login: (
    email: string,
    password: string,
    abortSignal: AbortSignal,
    successCallback: () => void,
    failedCallback: (message: string) => void
  ) => Promise<void>;
  user: User;
  isAdmin: boolean;
  permissions: Permission;
}

interface SessionProviderProps {
  children: ReactNode;
}

const SessionContext = createContext<Session | undefined>(undefined);

const SessionProvider: FC<SessionProviderProps> = ({ children }): ReactElement => {
  // TODO: Handle manual refreshing of page. Token is lost
  const [token, setToken] = useState<Token | undefined>(undefined);
  const [user, setUser] = useState<User>(new User());
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<Permission>(new Permission([]));

  const login = useCallback(
    async (
      email: string,
      password: string,
      abortSignal: AbortSignal,
      successCallback: () => void,
      failureCallback: (message: string) => void
    ) => {
      const onSuccess = (token: Token, user: User) => {
        setUser(user);
        setToken(token);
        logger.info('Session Started');
        const perms = getPermissions(token.accessToken, () => onFailure('Error setting permissions. Logging out.'));
        setPermissions(perms);
        setIsAdmin(perms.isAdmin);
        successCallback && successCallback();
      };

      const onFailure = (message: string) => {
        failureCallback && failureCallback(message);
      };

      await apiLogin(email, password, abortSignal, onSuccess, onFailure);
    },
    []
  );

  return (
    <SessionContext.Provider
      value={{
        token,
        login,
        setToken,
        user,
        isAdmin,
        permissions,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;

export const useSession = (): Session => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within an Session Provider context.');
  }
  return context;
};
