import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import User from '../../../shared/types/user';
import { dateTimeFormat } from '../../../shared/dates/date';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import UserFlags from '../../../shared/enums/userFlags';
import Box from '@mui/material/Box/Box';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { ErrorOutline } from '@mui/icons-material';
import { hasFlag } from '../../../shared/enums/extensions';
import Icon from '@mui/material/Icon/Icon';
import React from 'react';

export interface DataTableProps {
  data: User[] | undefined;
  loading: boolean;
  setUserId: React.Dispatch<number>;
}

export const columns: GridColDef<User>[] = [
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 400,
    renderCell: (params: GridRenderCellParams<User, string>) => {
      const confirmed = hasFlag(params.row.flags, UserFlags.EmailConfirmed);
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            wordWrap: 'break-word',
          }}
        >
          {!confirmed && (
            <Tooltip title="Email is not confirmed.">
              <Icon sx={{ mr: 0.5 }}>
                <ErrorOutline color="warning" />
              </Icon>
            </Tooltip>
          )}
          {params.value}
        </Box>
      );
    },
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'lastOn',
    headerName: 'Last On',
    flex: 1,
    minWidth: 100,
    valueGetter: (value) => {
      if (!value) {
        return value;
      }
      return dateTimeFormat.format(new Date(value));
    },
  },
  {
    field: 'isActive',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<User, string>) => {
      return (
        <span style={{ color: params.row.isActive ? green[500] : red[500] }}>
          {params.row.isActive ? 'Active' : 'Inactive'}
        </span>
      );
    },
  },
  {
    field: 'flags',
    headerName: 'Role',
    minWidth: 100,
    flex: 1,
    valueGetter: (value) => {
      if ((value & UserFlags.Administrator) === UserFlags.Administrator) {
        return 'Administrator';
      }
      return 'User';
    },
  },
];
