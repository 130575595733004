import { useState, useEffect, FC, useCallback } from 'react';
import ReferralTable from '../../components/referralTable/ReferralTable';
import Patient, { PatientRow } from '../../shared/types/patient';
import Grid from '@mui/material/Grid';
import AppProps from '../../shared/types/appProps';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { getReferrals } from '../../api/referrals/requests';
import { useSession } from '../../components/hooks/useSession';
import ReferralSnackbar from '../../components/snackbars/ReferralSnackbar';
import ResultSummary from '../../components/workflows/ResultSummary';
import WorkflowResultSummary from '../../shared/types/workflowResultSummary';
import { getCategories } from '../../api/payors/requests';
import PayorCategory from '../../shared/types/payorCategory';

const handleReferralSort = (x: Patient | undefined, y: Patient | undefined) => {
  if (!x?.referralDate && !y?.referralDate) {
    return 0;
  }
  if (!x?.referralDate) {
    return 1;
  }
  if (!y?.referralDate) {
    return -1;
  }
  return x.referralDate > y.referralDate ? 1 : -1;
};

const Referrals: FC<AppProps> = (props) => {
  const [referralData, setReferralData] = useState<PatientRow[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [payorCategories, setPayorCategories] = useState<Map<number, PayorCategory>>();
  const [summaryData, setSummaryData] = useState<WorkflowResultSummary[]>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [updates, setUpdates] = useState<PatientRow[] | undefined>();
  const { notify, userSocket } = props;
  const { token, user } = useSession();

  const onSuccess = useCallback(
    (data: Patient[]) => {
      if (data && data.length > 0) {
        const existingUpdates = new Set(updates?.map((x) => x.id) || []);
        const existingReferrals = new Set(referralData?.map((x) => x.id) || []);
        const newUpdates = data.filter((x) => !existingUpdates.has(x.id) && !existingReferrals.has(x.id));
        if (newUpdates.length > 0 && !loading) {
          setUpdates((prevUpdates) => mergeUniquePatients(prevUpdates, newUpdates));
          setOpenSnackbar(true);
        }
      }
    },
    [referralData, updates, loading]
  );

  const onFailure = useCallback(
    (message: string | undefined) => {
      notify('Error', message || 'Network Error.', 'error');
      setLoading(false);
    },
    [notify]
  );

  const mergeUniquePatients = (existingPatients?: Patient[], newPatients?: Patient[]): PatientRow[] => {
    const combined = [...(existingPatients || []), ...(newPatients ?? []).sort(handleReferralSort || [])];
    const uniquePatientsMap = new Map(combined.map((patient) => [patient.id, patient]));
    return Array.from(uniquePatientsMap.values()).map((patient) => ({
      ...patient,
      loading: false,
    }));
  };

  const snackbarClick = () => {
    setLoading(true);
    setReferralData((prevData) => mergeUniquePatients(prevData, updates));
    setTimeout(() => setLoading(false), 1000);
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (token && payorCategories && !referralData) {
      getReferrals(
        token,
        user.id,
        abortController.signal,
        (data: Patient[]) => {
          setReferralData(data.map((x) => ({ ...x, loading: false })));
          setLoading(false);
        },
        onFailure
      );
    }
    return () => abortController.abort();
  }, [payorCategories, referralData, token, onFailure, user]);

  useEffect(() => {
    if (userSocket && userSocket.socket && userSocket.socket.readyState === WebSocket.OPEN) {
      userSocket.props.onSuccess = onSuccess;
      userSocket.props.onFailure = onFailure;
    }
  }, [userSocket, onFailure, onSuccess]);

  useEffect(() => {
    const abortController = new AbortController();
    if (token && !payorCategories) {
      getCategories(
        token,
        abortController.signal,
        (data: PayorCategory[]) => setPayorCategories(new Map(data.map((x) => [x.id, x]))),
        onFailure
      );
    }
    return () => abortController.abort();
  }, [payorCategories, token, onFailure]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Grid
        container
        direction={'row'}
        sx={{
          mt: 'auto',
          mb: 'auto',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={11} rowSpacing={10}>
          <ReferralSnackbar setOpen={setOpenSnackbar} open={openSnackbar} onclick={snackbarClick} />
          <Paper
            elevation={3}
            sx={{
              padding: '50px',
              borderRadius: '20px',
              position: 'relative',
              height: 'auto',
              width: '95%',
            }}
          >
            {userSocket && (
              <ResultSummary userSocket={userSocket} notify={notify} data={summaryData} setData={setSummaryData} />
            )}
            <Box>
              <ReferralTable
                payorCategories={payorCategories}
                data={referralData || []}
                setData={setReferralData}
                loading={loading}
                {...props}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={11}></Grid>
      </Grid>
    </Box>
  );
};

export default Referrals;
