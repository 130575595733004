import React, { useState, FC, MouseEvent } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import User from '../../shared/types/user';
import Grid from '@mui/material/Grid';
import { useSession } from '../hooks/useSession';
import { networkError } from '../../api/base';
import Box from '@mui/material/Box/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { adminChangePassword, changePassword } from '../../api/auth/authRequests';
import IconButton from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import InfoTooltip from '../inputs/InfoTooltip';
import { passwordErrors, passwordsMatch, validatePassword } from '../../shared/security/security';

interface Props {
  user: User;
  locked: boolean;
  showConfirm: boolean;
  setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<string>;
  setSuccessMessage: React.Dispatch<string>;
  isAdminPage: boolean;
}

const PasswordManager: FC<Props> = (props) => {
  const { locked, showConfirm, setShowConfirm, setSuccessMessage, user, setError, isAdminPage } = props;
  const { token } = useSession();
  const [passwordError, setPasswordError] = useState<string>();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const [dirty, setDirty] = useState(false);

  // Events
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { message, isValid } = validatePassword(password, confirmPassword);

    if (!isValid) {
      setPasswordError(message);
      return;
    }
    if (dirty) {
      setShowConfirm(true);
    }
  };

  const clearFields = () => {
    setPassword('');
    setConfirmPassword('');
    setPasswordError('');
    setCurrentPassword('');
    setError('');
  };

  const update = async () => {
    const abortController = new AbortController();
    const onSuccess = (message: string) => {
      setLoading(false);
      setSuccessMessage(message);
      setDirty(false);
      clearFields();
    };
    const onFailure = (message: string | undefined) => {
      setLoading(false);
      setError(message || networkError);
    };
    setLoading(true);
    if (isAdminPage) {
      await adminChangePassword(user.id, password, token?.accessToken, abortController.signal, onSuccess, onFailure);
    } else {
      await changePassword(
        user.id,
        currentPassword,
        password,
        token?.accessToken,
        abortController.signal,
        onSuccess,
        onFailure
      );
    }
  };

  const handleInputBlur = (_e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setError('');
    setSuccessMessage('');
    setPasswordError(undefined);
  };

  const handlePasswordBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, compare: string) => {
    if (Boolean(compare) && !passwordsMatch(e.target.value, compare)) {
      setPasswordError('Passwords must match');
      setError('');
      setSuccessMessage('');
    } else {
      handleInputBlur(e);
    }
  };
  return (
    <Box component={'form'} onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <ConfirmDialog open={showConfirm} setOpen={setShowConfirm} callback={update} />
        <Grid item xs={12} sm={8}>
          {isAdminPage ? (
            <Box sx={{ width: '240px' }} />
          ) : (
            <TextField
              required
              id="current-password"
              name="current-password"
              label="Current Password"
              fullWidth
              onChange={(e) => {
                setCurrentPassword(e.target.value);
                setDirty(true);
              }}
              onBlur={handleInputBlur}
              disabled={locked}
              value={currentPassword}
              autoComplete="current-password"
              type={showCurrentPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowCurrentPassword((show) => !show)}
                      onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '106%' }}>
            <TextField
              required
              id="password"
              name="password"
              label="Password"
              fullWidth
              error={Boolean(passwordError)}
              onBlur={(e) => handlePasswordBlur(e, confirmPassword || '')}
              onChange={(e) => {
                setPassword(e.target.value);
                setDirty(true);
              }}
              disabled={locked}
              value={password}
              autoComplete="new-password"
              helperText={passwordError ? passwordError : ''}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <InfoTooltip
              title={
                <>
                  {passwordErrors.map((x, index) => (
                    <span key={index}>
                      {x.error}
                      <br />
                    </span>
                  ))}
                </>
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            required
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            fullWidth
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setDirty(true);
            }}
            onBlur={(e) => handlePasswordBlur(e, password || '')}
            disabled={locked}
            value={confirmPassword}
            autoComplete="new-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={0} sm={7}></Grid>
        <Grid item xs={12} sm={5}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button disabled={locked} type="submit" variant="contained">
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default PasswordManager;
