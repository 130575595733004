import React, { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callback: () => Promise<void>;
}

const ConfirmDialog: FC<Props> = ({ open, setOpen, callback }) => {
  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(false);
    callback();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            alignSelf: 'flex-start',
            marginTop: '25vh',
            maxWidth: '25vw',
            padding: '.5rem',
            borderRadius: '10px',
          },
        }}
        fullWidth={true}
      >
        <DialogTitle id="confirmation-dialog-title">{'Confirm Action'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">Do you want to save the changes?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="small" variant="contained" onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
