import { fetchGetAsync, baseURL } from '../base';
import Token from '../../shared/types/token';
import WorkflowType from '../../shared/enums/workflowType';
import WorkflowResultSummary from '../../shared/types/workflowResultSummary';
import Workflow from '../../shared/types/workflow';
import { FunctionDataCallback } from '../../shared/types/callbacks';
import WorkflowResult from '../../shared/types/workflowResult';
import Schedule from '../../shared/types/schedule';
const controller = `${baseURL}/workflows`;

/**
 * Gets latest Referral workflow results.
 * @param token Access Token
 * @param signal Abort Signal
 * @param onSuccess Success Callback
 * @param onFailure Failure Callback
 */
export const getLatestReferralWorkflowResults = async (
  token: Token | undefined,
  signal: AbortSignal,
  onSuccess: FunctionDataCallback<WorkflowResultSummary[]>,
  onFailure: FunctionStringCallback | undefined
) => {
  const url = new URL(`${controller}/referrals/latest`);
  await fetchGetAsync(url, token, signal, onSuccess, onFailure);
};

/**
 *
 * @param token Access Token
 * @param signal Abort Signal
 * @param onSuccess Success Callback
 * @param onFailure Failure Callback
 */
export const getWorkflows = async (
  token: Token | undefined,
  signal: AbortSignal,
  onSuccess?: FunctionDataCallback<Workflow[]>,
  onFailure?: FunctionStringCallback
) => {
  const url = new URL(`${controller}?type=${WorkflowType.Schedules}`);
  await fetchGetAsync(url, token, signal, onSuccess, onFailure);
};

/**
 *
 * @param id Workflow Id
 * @param token Access Token
 * @param signal Abort Signal
 * @param onSuccess Success Callback
 * @param onFailure Failure Callback
 */
export const getHistory = async (
  id: number,
  type: number,
  token: Token | undefined,
  signal: AbortSignal,
  onSuccess?: FunctionDataCallback<WorkflowResult<Schedule[]>[]>,
  onFailure?: FunctionStringCallback
) => {
  const url = new URL(`${controller}/${type}/${id}/history`);
  await fetchGetAsync(url, token, signal, onSuccess, onFailure);
};
