import React, { useState, useEffect, FC } from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Workflow from '../../shared/types/workflow';
import { getWorkflows } from '../../api/workflows/requests';
import { useSession } from '../hooks/useSession';
import WorkflowList from './WorkflowList';
import FormControl from '@mui/material/FormControl';
import SeachField from '../inputs/Search';
import Divider from '@mui/material/Divider';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { search } from '../hooks/useDebounce';
import WorkflowDrawerProps from './WorkflowDrawerProps';
import Loader from '../loader/Loader';

const drawerWidth = 300;

const WorkflowDrawer: FC<WorkflowDrawerProps> = (props) => {
  const { notify, openDrawer, setOpenDrawer } = props;
  const [workflows, setWorkflows] = useState<Workflow[]>();
  const [workflowSearchList, setWorkflowSearchList] = useState<Workflow[]>();
  const [loading, setLoading] = useState(true);
  const { token } = useSession();

  useEffect(() => {
    const abortController = new AbortController();
    if (!workflows && token) {
      getWorkflows(
        token,
        abortController.signal,
        (data) => {
          setWorkflowSearchList(data);
          setWorkflows(data);
          setLoading(false);
        },
        (message) => {
          notify('Error loading data', message, 'error');
          setLoading(false);
        }
      );
    }
    return () => abortController.abort();
  }, [workflows, setWorkflows, token, notify]);

  const onSearchChange = (input: string) => {
    const searchflows = search([...(workflows || [])], input, 'name');
    setWorkflowSearchList(searchflows);
  };

  return (
    <>
      <IconButton
        onClick={() => setOpenDrawer((prev) => !prev)}
        sx={{
          position: 'absolute',
          left: openDrawer ? drawerWidth - 15 : 0,
          top: '5rem',
          zIndex: 2000,
          borderRadius: '50%',
          padding: '1px',
          border: '1px solid',
          borderColor: 'primary.main',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'lightgrey',
          },
          transform: 'translateY(-50%)',
          transition: 'left 0.2s ease',
        }}
        size="small"
      >
        {openDrawer ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            paddingTop: '55px',
          },
        }}
        variant="persistent"
        anchor="left"
        open={openDrawer}
      >
        <Toolbar>
          <FormControl margin="normal">
            <Typography color={'info.main'} fontWeight={550} variant={'h6'}>
              Workflows
            </Typography>
            <SeachField onChange={onSearchChange} placeholder="Search Workflows" size="small" margin="normal" />
          </FormControl>
        </Toolbar>
        <Divider />
        {workflowSearchList && <WorkflowList {...props} data={workflowSearchList} />}
        <Loader loading={loading} />
      </Drawer>
    </>
  );
};

export default WorkflowDrawer;
