import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const SomethingWentWrong = () => {
  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box textAlign="center" height="75vh">
        <ErrorOutlineIcon color="error" style={{ fontSize: 80 }} />
        <Typography variant="h4" gutterBottom>
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We are sorry, but an unexpected error occurred.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.replace('/login')}
          style={{ marginTop: 20 }}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default SomethingWentWrong;
