import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UserAvatar from './UserAvatar';
import Tooltip from '@mui/material/Tooltip';
import { useSession } from '../hooks/useSession';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { NavLink } from 'react-router-dom';

const AccountMenu = () => {
  const { user } = useSession();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={`Account manager for ${user.firstName} ${user.lastName}`}>
        <UserAvatar sx={{ height: 32, width: 32, fontSize: '.9rem', cursor: 'pointer' }} onClick={handleClick} />
      </Tooltip>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            left: 'auto',
            right: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              paddingRight: '1rem',
              marginTop: '2rem',
            }}
          >
            <UserAvatar sx={{ height: 60, width: 60 }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginTop: '.5rem' }}>
            <Typography fontWeight={'bold'}>
              {user.firstName} {user.lastName}
            </Typography>
            <Typography fontSize={'small'}>{user.email}</Typography>
            <Divider sx={{ margin: '0.5rem 0' }} />
            <MenuItem
              sx={{
                fontSize: '.9rem',
                fontWeight: '100',
                padding: '10px 20px',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
              }}
              onClick={handleClose}
            >
              <Button component={NavLink} size="small" to="/account">
                Manage Account
              </Button>
            </MenuItem>
          </Box>
        </Box>
      </Menu>
    </div>
  );
};

export default AccountMenu;
