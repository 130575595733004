import PayorCategory from './payorCategory';

class Payor {
  id: number;
  name: string;
  isActive: boolean;
  categoryId: number | undefined;
  category: PayorCategory;

  constructor(
    id?: number,
    name?: string,
    isActive?: boolean,
    category?: PayorCategory,
    categoryId?: number | undefined
  ) {
    this.id = id ?? 0;
    this.name = name ?? '';
    this.isActive = isActive ?? false;
    this.categoryId = categoryId;
    this.category = category ?? new PayorCategory();
  }
}

export default Payor;
