import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { PatientRow } from '../../shared/types/patient';
import Address from '../../shared/types/address';
import Link from '@mui/material/Link/Link';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import IconButton from '@mui/material/IconButton/IconButton';
import { dateFormat, dateTimeFormat } from '../../shared/dates/date';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import MessageStatus from '../../shared/enums/messageStatus';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import PayorCategory from '../../shared/types/payorCategory';
import PatientPayor from '../../shared/types/patientPayor';

const getTextColor = (hex: string | undefined) => {
  if (!hex) {
    return '#000000';
  }
  // Check to ensure that if the background is white/near white, we display a black text.
  const [r, g, b] = [0, 2, 4].map((offset) => parseInt(hex.slice(offset, offset + 2), 16));
  const threshold = 200;
  return r > threshold && g > threshold && b > threshold ? '#000000' : '#FFFFFF';
};

export const columns = (payorCategories: Map<number, PayorCategory>): GridColDef<PatientRow>[] => [
  {
    field: 'messageStatus',
    headerName: 'Status',
    minWidth: 80,
    width: 80,
    renderCell: (params: GridRenderCellParams<PatientRow, string>) => {
      if (params.row.loading) {
        return (
          <div style={{ margin: '12px' }}>
            <CircularProgress size={20} />
          </div>
        );
      }
      const read = params.row.messageStatus === MessageStatus.Read;
      return (
        <Tooltip title={`${read ? 'Mark as Unread' : 'Mark as Read'}`}>
          <IconButton>
            {!read && <MarkunreadIcon color="info" />}
            {read && <MarkAsUnreadIcon color="success" />}
          </IconButton>
        </Tooltip>
      );
    },
    editable: true,
  },
  {
    field: 'name',
    headerName: 'Patient Name',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
  },
  {
    field: 'birthdate',
    headerName: 'Birthdate',
    flex: 1,
    minWidth: 100,
    valueGetter: (value) => {
      if (!value) {
        return value;
      }
      return dateFormat.format(new Date(value));
    },
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 1,
    minWidth: 100,
    valueGetter: (value) => {
      const address = value as Address;
      if (!address) {
        return value;
      }
      return `${address.street}, ${address.city}, ${address.state} ${address.zipcode}`;
    },
  },
  {
    field: 'primaryInsurance',
    headerName: 'Primary Insurance',
    flex: 1,
    minWidth: 100,
    cellClassName: 'break-cell',
  },
  {
    field: 'payor',
    headerName: 'Payor Type',
    minWidth: 100,
    valueGetter: (value: PatientPayor) => {
      const category = payorCategories.get(value?.categoryId ?? 0);
      if (!category) {
        return '';
      }
      return category.name;
    },
    renderCell: (params: GridRenderCellParams<PatientRow, string>) => {
      const { payor } = params.row;
      const category = payorCategories.get(payor?.categoryId ?? 0);
      if (!category || !category.color) {
        return <></>;
      }
      return (
        <div
          style={{
            backgroundColor: `#${category.color}`,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: getTextColor(category.color),
          }}
        >
          {category.name ?? ''}
        </div>
      );
    },
  },
  {
    field: 'referralSource',
    headerName: 'Referral Source',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'ehrLink',
    headerName: 'EHR Link',
    flex: 1,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<PatientRow, string>) => {
      if (!params.value || params.value === '') {
        return;
      }
      return (
        <Link href={params.value} target="_blank" rel="noopener">
          {params.value}
        </Link>
      );
    },
  },
  {
    field: 'referralDate',
    headerName: 'Date',
    minWidth: 100,
    width: 105,
    valueGetter: (value) => {
      if (!value) {
        return value;
      }
      return new Date(value);
    },
    renderCell: (params: GridRenderCellParams<PatientRow, Date | undefined>) => {
      if (!params.value) {
        return;
      }
      return <span>{dateTimeFormat.format(params.value)}</span>;
    },
  },
];
