import React, { useState, useEffect, FC } from 'react';
import { getHistory } from '../../api/workflows/requests';
import { useSession } from '../../components/hooks/useSession';
import HistoryTable from '../../components/workflows/HistoryTable';
import { NotifyCallback } from '../../shared/types/callbacks';
import Schedule from '../../shared/types/schedule';
import WorkflowResult from '../../shared/types/workflowResult';

interface Props {
  selectedWorkflow: number;
  type: number;
  notify: NotifyCallback;
}

const WorkflowHistory: FC<Props> = ({ selectedWorkflow, type, notify }) => {
  const [data, setData] = useState<WorkflowResult<Schedule[]>[]>();
  const [loading, setLoading] = useState(true);
  const { token } = useSession();

  useEffect(() => {
    const abortController = new AbortController();
    if (selectedWorkflow && token) {
      getHistory(
        selectedWorkflow,
        type,
        token,
        abortController.signal,
        (data) => {
          setData(data);
          setLoading(false);
        },
        (message) => {
          notify('Error', message, 'error');
          setLoading(false);
        }
      );
    }
    return () => {
      abortController.abort();
      setLoading(true);
    };
  }, [notify, selectedWorkflow, type, token]);

  const tableProps = {
    loading,
    notify,
    selectedWorkflow,
    data: data || [],
  };

  return <HistoryTable {...tableProps}></HistoryTable>;
};

export default WorkflowHistory;
