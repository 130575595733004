import React, { FC } from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { WorkflowPage } from '../../shared/types/pages';
import WorkflowDrawerProps from './WorkflowDrawerProps';
import Collapse from '@mui/material/Collapse';

const WorkflowList: FC<WorkflowDrawerProps> = (props) => {
  const { data, onSelectWorkflow, onSelectWorkflowPage, selectedWorkflow, selectedPage } = props;

  return (
    <List>
      {data.map((workflow) => (
        <React.Fragment key={workflow.id}>
          <ListItemButton onClick={() => onSelectWorkflow(workflow.id, workflow.type)}>
            <ListItemText primary={workflow.name} primaryTypographyProps={{ variant: 'body1' }} />
          </ListItemButton>
          <Collapse in={selectedWorkflow === workflow.id} timeout="auto">
            <List component="div" disablePadding>
              {Object.values(WorkflowPage)
                .filter((value) => typeof value === 'number')
                .map((value) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    key={`${workflow.id}_${WorkflowPage[value as WorkflowPage]}`}
                    selected={selectedPage === (value as WorkflowPage)}
                    onClick={() => onSelectWorkflowPage(value as WorkflowPage)}
                  >
                    <ListItemText
                      primary={WorkflowPage[value as WorkflowPage].toString().replace('_', ' ')}
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItemButton>
                ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
};

export default WorkflowList;
