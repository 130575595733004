import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const AccountVerified = () => {
  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: '15vh',
      }}
    >
      <Box textAlign="center" padding={3} boxShadow={3} borderRadius={2} style={{ backgroundColor: '#f9f9f9' }}>
        <CheckCircleOutlineIcon style={{ fontSize: 60, color: 'green', marginBottom: '16px' }} />
        <Typography variant="h5" gutterBottom>
          Email Confirmed!
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Your email has been verified! You can now log in to access your account.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.replace('/login')}
          style={{ marginTop: 20, padding: '10px 20px' }}
        >
          Go to Login
        </Button>
      </Box>
    </Container>
  );
};

export default AccountVerified;
