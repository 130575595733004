const requestType = {
  Unknown: 0,
  HeartBeat: 1,
  Auth: 2,
  // Referrals
  GetReferrals: 3,
  UpdateMessage: 4,
  TrackSummaries: 5,
  UpdateSummaries: 6,
};

export default requestType;
