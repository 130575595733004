import Token from '../shared/types/token';
import logger from '../shared/logging/logger';
import { FunctionDataCallback } from '../shared/types/callbacks';

const production = process.env.REACT_APP_NODE_ENV?.toLowerCase() === 'production';
export const baseURL = production ? 'https://api.streamlineautomate.com/api' : 'http://localhost:8080/api';
export const baseWS = production ? 'wss://api.streamlineautomate.com/api' : 'ws://localhost:8080/api';

export const networkError = 'There was an issue loading data. Please contact MEDsys Support';

export enum HTTPMethod {
  GET = 'GET',
}

/**
 *
 * @param input Url
 * @param token Access Token
 * @param signal Abort Controller Signal
 * @param onSuccess Success Callback
 * @param onFailure FailureCallback
 */
export const fetchGetAsync = async <T>(
  input: URL | RequestInfo,
  token: Token | string | undefined,
  signal?: AbortSignal,
  onSuccess?: FunctionDataCallback<T>,
  onFailure?: FunctionStringCallback
) => {
  try {
    const response = await fetch(input, {
      method: HTTPMethod.GET,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${typeof token === 'string' ? token : token?.accessToken}`,
      },
      signal: signal,
    });
    if (response.ok) {
      const { data, message, success } = await response.json().catch((e) => {
        throw new Error(e);
      });
      if (!success) {
        throw new Error(message);
      }
      onSuccess && onSuccess(data);
    } else {
      throw new Error(networkError);
    }
  } catch (err: unknown) {
    if (!signal?.aborted) {
      logger.error(err);
      onFailure && onFailure(err && err.toString ? err.toString() : networkError);
    }
  }
};
