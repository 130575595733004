import React, { FC } from 'react';
import User from '../../shared/types/user';
import { SecuritySetting as SecurityPage } from '../../shared/types/permissions';
import PasswordManager from './PasswordManager';
import Box from '@mui/material/Box';
import Permissions from './Permissions';

interface Props {
  user: User;
  locked: boolean;
  showConfirm: boolean;
  setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<string>;
  setSuccessMessage: React.Dispatch<string>;
  isAdminPage: boolean;
  selectedSetting: SecurityPage | undefined;
}

const UserSecurity: FC<Props> = (props) => {
  const { locked, showConfirm, setShowConfirm, setSuccessMessage, user, setError, isAdminPage, selectedSetting } =
    props;

  const securityProps = {
    user,
    locked,
    showConfirm,
    setShowConfirm,
    setError,
    setSuccessMessage,
    isAdminPage,
  };

  return (
    <>
      {selectedSetting === SecurityPage.Password && (
        <Box>
          <PasswordManager {...securityProps} />
        </Box>
      )}
      {selectedSetting === SecurityPage.Permissions && (
        <Box>
          <Permissions {...securityProps} />
        </Box>
      )}
    </>
  );
};

export default UserSecurity;
