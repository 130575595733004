import Box from '@mui/material/Box/Box';
import { GridToolbarColumnsButton, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { GridToolbarContainer } from '@mui/x-data-grid/components/containers/GridToolbarContainer';
import { GridToolbarFilterButton } from '@mui/x-data-grid/components/toolbar/GridToolbarFilterButton';

const GridToolbar = () => {
  return (
    <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', width: '99%' }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </Box>
      <GridToolbarQuickFilter sx={{ ml: 'auto' }} />
    </GridToolbarContainer>
  );
};
export default GridToolbar;
