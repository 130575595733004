import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import Button from '@mui/material/Button';
import { useSession } from '../hooks/useSession';
import Stack from '@mui/material/Stack';
import AccountMenu from './AccountMenu';
import { Page } from '../../shared/types/permissions';

interface NavbarProps {
  onLogout: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onLogout }) => {
  const { permissions } = useSession();
  return (
    <nav className="navbar">
      <div className="left">
        {!permissions.IsRestricted(Page.Home) && (
          <Button component={NavLink} size="small" to="/home">
            Home
          </Button>
        )}
        {!permissions.IsRestricted(Page.Referrals) && (
          <Button component={NavLink} size="small" to="/referrals">
            Referrals
          </Button>
        )}
        {!permissions.IsRestricted(Page.Workflows) && (
          <Button component={NavLink} size="small" to="/workflows">
            Workflows
          </Button>
        )}
        {!permissions.IsRestricted(Page.Administrator) && (
          <Button component={NavLink} size="small" to="/admin">
            Administration
          </Button>
        )}
      </div>
      <div className="right">
        <Stack direction="row" spacing={1}>
          <AccountMenu />
          <Button size="small" onClick={onLogout}>
            Log Out
          </Button>
        </Stack>
      </div>
    </nav>
  );
};
export default Navbar;
