import { FC, useEffect, useState } from 'react';
import AppProps from '../../../shared/types/appProps';
import User from '../../../shared/types/user';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import UserTable from './UserTable';
import { getUsers } from '../../../api/users/requests';
import { networkError } from '../../../api/base';
import { useSession } from '../../../components/hooks/useSession';
import { Button } from '@mui/material';
import AddUser from './AddUser';
import UserComponent from './User';

const Users: FC<AppProps> = (props) => {
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [addUser, setAddUser] = useState(false);
  const [userId, setUserId] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const { notify } = props;
  const { token } = useSession();

  useEffect(() => {
    const abortController = new AbortController();
    if (!users) {
      const onSuccess = (data: User[]) => {
        setLoading(false);
        setUsers(data);
      };
      const onFailure = (message: string | undefined) => {
        setLoading(false);
        notify('Error', message || networkError, 'error');
      };
      setLoading(true);
      getUsers(token, abortController.signal, onSuccess, onFailure);
    }
    return () => {
      abortController.abort();
    };
  }, [users, token, notify]);

  return (
    <Grid
      item
      sx={{
        marginTop: '3rem',
      }}
      xs={12}
    >
      {userId > 0 ? (
        <UserComponent setUsers={setUsers} notify={notify} setUserId={setUserId} userId={userId} isAdminPage={true} />
      ) : addUser ? (
        <AddUser setUsers={setUsers} notify={notify} setUserId={setUserId} setAddUser={setAddUser} />
      ) : (
        <>
          <Box
            sx={{
              textAlign: 'right',
              paddingBottom: '1rem',
            }}
          >
            <Button variant={'contained'} onClick={() => setAddUser(true)}>
              Add
            </Button>
          </Box>
          <Box sx={{ height: '85.5vh', width: '80vw' }}>
            <UserTable loading={loading} data={users || []} setUserId={setUserId} />
          </Box>
        </>
      )}
    </Grid>
  );
};

export default Users;
