import React, { FC, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useSession } from '../hooks/useSession';
import { refreshToken } from '../../api/auth/authRequests';
import Token from '../../shared/types/token';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface Props extends React.ComponentPropsWithoutRef<typeof Dialog> {
  logout: () => void;
  notify: (title: string, message: string, variant: 'success' | 'error' | 'warning' | 'info') => void;
  setCheckSession: React.Dispatch<boolean>;
}

const CheckSession: FC<Props> = ({ logout, ...props }) => {
  const [timeLeft, setTimeLeft] = useState(60); // 60 seconds
  const { open, notify, setCheckSession } = props;
  const { token, user, setToken } = useSession();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          onCancel();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  });

  const onCancel = () => {
    closeDialog();
    logout();
  };

  const onConfirm = async () => {
    setLoading(true);
    await refreshToken(
      user.id,
      token?.refreshToken,
      async (token: Token) => {
        setToken(token);
        // Delay needed to prevent race condition where Dialog opens again.
        await new Promise((resolve) => setTimeout(resolve, 500));
        closeDialog();
      },
      () => {
        notify('Error', 'Error refreshing token.  Please log in again.', 'error');
        closeDialog();
        logout();
      }
    );
  };

  const closeDialog = () => {
    setCheckSession(false);
    setLoading(false);
    setTimeLeft(60);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            alignSelf: 'flex-start',
            marginTop: '25vh',
            maxWidth: '30vw',
            padding: '.5rem',
            borderRadius: '10px',
          },
        }}
        fullWidth={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="confirmation-dialog-title">{'Session Expiring'}</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DialogContentText id="confirmation-dialog-description">
              Your session is about to expire in {timeLeft} seconds. Would you like to continue your session?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" disabled={loading} onClick={onCancel}>
            Logout
          </Button>
          <Button size="small" variant="contained" disabled={loading} autoFocus onClick={onConfirm}>
            Stay Logged In
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CheckSession;
