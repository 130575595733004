import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const Unauthorized = () => {
  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box textAlign="center" height="75vh">
        <LockOutlinedIcon color="error" style={{ fontSize: 80 }} />
        <Typography variant="h4" gutterBottom>
          Unauthorized Access
        </Typography>
        <Typography variant="body1" gutterBottom>
          You do not have permission to view this page.
        </Typography>
      </Box>
    </Container>
  );
};

export default Unauthorized;
