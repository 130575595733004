import { baseURL } from '../base';
import logger from '../../shared/logging/logger';
import { networkError } from '../base';
const controller = `${baseURL}/users`;

export const getUsers = async (token, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal: signal,
    });
    logger.debug(response);
    if (response.ok) {
      const { success, data } = await response.json();
      if (success) {
        onSuccess && onSuccess(data);
      }
    } else {
      onFailure && onFailure(networkError);
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(error);
    }
  }
};

export const getUser = async (token, id, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}/user/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal: signal,
    });
    const { success, data } = await response.json().catch(() => {
      throw new Error(
        "Unable to retrieve the requested user's details. Please try again or contact support if the issue continues."
      );
    });

    if (!success) {
      throw new Error(
        "Unable to retrieve the requested user's details. Please try again or contact support if the issue continues."
      );
    }
    onSuccess && onSuccess(data);
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(error);
    }
  }
};

export const updateUser = async (token, id, request, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}/user/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal: signal,
      body: JSON.stringify(request),
    });
    logger.debug(response);
    if (response.ok) {
      const { success, data } = await response.json();
      if (success) {
        onSuccess && onSuccess(data);
      }
    } else {
      onFailure && onFailure(networkError);
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(error);
    }
  }
};

export const updatePermissions = async (token, id, request, signal, onSuccess, onFailure) => {
  try {
    const response = await fetch(`${controller}/user/${id}/permissions`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token.accessToken}`,
      },
      signal,
      body: JSON.stringify(request),
    });
    if (response.ok) {
      const { data } = await response.json().catch((e) => {
        throw new Error(e);
      });
      onSuccess && onSuccess(data);
    } else {
      throw new Error(networkError);
    }
  } catch (error) {
    if (!signal.aborted) {
      logger.error(error);
      onFailure && onFailure(error);
    }
  }
};
