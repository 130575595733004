import { FC, useCallback } from 'react';
import { DataGrid, GridCallbackDetails, GridCellParams, GridEventListener, MuiEvent } from '@mui/x-data-grid';
import { columns } from './tableRenders';
import './table.css';
import GridToolbar from '../toolbars/GridToolbar';
import Patient, { PatientRow } from '../../shared/types/patient';
import UserSocket from '../../shared/types/userSocket';
import { updateMessageStatus } from '../../api/sockets/user/userRequests';
import MessageStatus from '../../shared/enums/messageStatus';
import PayorCategory from '../../shared/types/payorCategory';

export interface Props {
  data: PatientRow[];
  setData: React.Dispatch<React.SetStateAction<PatientRow[] | undefined>>;
  loading: boolean;
  userSocket: UserSocket | undefined;
  notify: (title: string, message: string, variant: 'success' | 'error' | 'warning' | 'info') => void;
  payorCategories: Map<number, PayorCategory> | undefined;
}

const ReferralTable: FC<Props> = (props: Props) => {
  const { data, loading, userSocket, notify, setData, payorCategories } = props;
  const messageClick = useCallback(
    (patientId: number, messageStatus: MessageStatus) => {
      if (userSocket && userSocket.socket && userSocket.socket.readyState === WebSocket.OPEN) {
        userSocket.props.onMessageUpdateSuccess = (data) => {
          setData(
            (prev) =>
              prev &&
              prev.map((row) =>
                row.id === data.patientId ? { ...row, messageStatus: data.status, loading: false } : row
              )
          );
        };
        userSocket.props.onMessageUpdateFailure = () => {
          notify('Error', 'Message not updated. Please contact MEDsys Support.', 'error');
          setData((prev) => prev && prev.map((row) => (row.id === patientId ? { ...row, loading: false } : row)));
        };
        setData((prev) => prev && prev.map((row) => (row.id === patientId ? { ...row, loading: true } : row)));
        const newStatus = messageStatus === MessageStatus.Read ? MessageStatus.Unread : MessageStatus.Read;
        userSocket.socket.send(JSON.stringify(updateMessageStatus(patientId, newStatus)));
      }
    },
    [userSocket, notify, setData]
  );

  const handleCellClick: GridEventListener<'cellClick'> = (
    params: GridCellParams<Patient>,
    _e: MuiEvent<React.MouseEvent<HTMLElement>>,
    _d: GridCallbackDetails
  ) => {
    if (params.field === 'messageStatus') {
      messageClick(params.row.id, params.row.messageStatus);
    }
  };

  return (
    <div className="referral-container">
      <DataGrid
        rows={data || []}
        columns={columns(payorCategories || new Map())}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
          sorting: {
            sortModel: [{ field: 'referralDate', sort: 'asc' }],
          },
        }}
        getRowHeight={() => 'auto'}
        rowPositionsDebounceMs={10}
        getEstimatedRowHeight={() => 61}
        sortingOrder={['desc', 'asc']}
        rowSelection={false}
        onCellClick={handleCellClick}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            sx: {
              paddingBottom: '1rem',
            },
          },
        }}
      />
    </div>
  );
};

export default ReferralTable;
