import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

interface Props {
  onclick: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReferralSnackbar = (props: Props) => {
  const { setOpen, open, onclick } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        sx={{ position: 'absolute', right: 8, top: 4 }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <>
      <Snackbar
        open={open}
        onClose={handleClose}
        sx={{ marginTop: '45px' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="success" action={action} sx={{ width: '100%', paddingRight: 0 }}>
          New Referrals Available!
          <Box mt={2} ml={15}>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                onclick();
                handleClose();
              }}
            >
              Refresh
            </Button>
          </Box>
        </Alert>
      </Snackbar>
    </>
  );
};

export default ReferralSnackbar;
