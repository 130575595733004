import { FC, useEffect, useState } from 'react';
import AppProps from '../../../shared/types/appProps';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { networkError } from '../../../api/base';
import { useSession } from '../../../components/hooks/useSession';
import { Button } from '@mui/material';
import { getCategories } from '../../../api/payors/requests';
import PayorCategory from '../../../shared/types/payorCategory';
import Category from './Category';
import PayorCategoryTable from '../../../components/payors/CategoryTable';

const Categories: FC<AppProps> = (props) => {
  const [categories, setCategories] = useState<PayorCategory[] | undefined>(undefined);
  const [category, setCategory] = useState<PayorCategory | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const { notify } = props;
  const { token } = useSession();

  useEffect(() => {
    const abortController = new AbortController();
    if (!categories) {
      const onSuccess = (data: PayorCategory[]) => {
        setLoading(false);
        setCategories(data);
      };
      const onFailure = (message: string | undefined) => {
        setLoading(false);
        notify('Error', message || networkError, 'error');
      };
      setLoading(true);
      getCategories(token, abortController.signal, onSuccess, onFailure);
    }
    return () => {
      abortController.abort();
    };
  }, [categories, token, notify]);

  return (
    <Grid
      item
      sx={{
        marginTop: '3rem',
      }}
    >
      {category ? (
        <>
          <Category
            setCategories={setCategories}
            setCategory={setCategory}
            notify={notify}
            category={category}
          ></Category>
        </>
      ) : (
        <>
          <Box
            sx={{
              textAlign: 'right',
              paddingBottom: '1rem',
            }}
          >
            <Button variant={'contained'} onClick={() => setCategory(new PayorCategory())}>
              Add
            </Button>
          </Box>
          <Box sx={{ height: '85.5vh', width: '80vw' }}>
            <PayorCategoryTable loading={loading} data={categories || []} setCategory={setCategory} />
          </Box>
        </>
      )}
    </Grid>
  );
};

export default Categories;
