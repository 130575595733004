import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

interface InfoTooltipProps {
  title: React.ReactNode;
  icon?: React.ReactNode; // The icon is optional
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ title, icon = <HelpIcon /> }) => {
  return (
    <Tooltip title={title} placement="right">
      <IconButton>{icon}</IconButton>
    </Tooltip>
  );
};

export default InfoTooltip;
