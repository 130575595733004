import logger from '../logging/logger';

export const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

export const dateTimeFormatAlt = new Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
});

export const dateFormat = new Intl.DateTimeFormat('en-US', {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
});

export const utcToLocal = (date: Date) => {
  // Dates interpreted by JS as local, but they are actually UTC.
  // Appending a Z so that it is in UTC
  try {
    return new Date(date + 'Z').toLocaleString();
  } catch (err) {
    logger.error('Error parsing date', err);
    return date.toLocaleString();
  }
};
