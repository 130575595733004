import { PermissionGroups } from './permissions';

class User {
  id: number;
  isActive: boolean;
  email: string;
  firstName: string;
  lastName: string;
  flags: number;
  lastOn?: Date | undefined;
  permissionGroups: PermissionGroups[];

  constructor() {
    this.id = 0;
    this.isActive = true;
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.flags = 0;
    this.permissionGroups = [];
  }
}

export default User;
