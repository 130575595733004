import { FC, useCallback } from 'react';
import { DataGrid, GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { DataTableProps, columns } from './tableRenders';
import Payor from '../../shared/types/payor';
import GridToolbar from '../toolbars/GridToolbar';

const PayorTable: FC<DataTableProps> = (props: DataTableProps) => {
  const { data, loading, setPayor } = props;

  const onRowClick: GridEventListener<'rowClick'> = useCallback(
    (params: GridRowParams<Payor>) => {
      setPayor(params.row);
    },
    [setPayor]
  );

  return (
    <DataGrid
      rows={data || []}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 50 },
        },
        sorting: {
          sortModel: [{ field: 'isActive', sort: 'desc' }],
        },
      }}
      sx={{
        '.MuiDataGrid-row': {
          cursor: 'pointer',
        },
        '.MuiDataGrid-cell:focus': {
          outline: 'none',
        },
      }}
      onRowClick={onRowClick}
      sortingOrder={['desc', 'asc']}
      rowSelection={true}
      disableMultipleRowSelection={true}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      loading={loading}
      density={'compact'}
      getRowHeight={() => 'auto'}
      rowPositionsDebounceMs={10}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          sx: {
            paddingBottom: '1rem',
          },
        },
      }}
    />
  );
};

export default PayorTable;
