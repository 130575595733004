import logger from '../logging/logger';

export enum PermissionGroups {
  None = 0,
  Master = 1,
  Administrator = 2,
  View_Referrals = 3,
  View_Workflows = 4,
}

export enum Page {
  Home = 1,
  Administrator = 2,
  Account = 3,
  Referrals = 4,
  Workflows = 5,
}

export enum SecuritySetting {
  Password,
  Permissions,
}

const basicUserPages = [Page.Account, Page.Home];

export const getPermissions = () => {
  const ignoreGroups = [PermissionGroups.None, PermissionGroups.Master];
  const groups = Object.values(PermissionGroups).filter((value) => typeof value === 'number') as PermissionGroups[];
  return groups.filter((x) => !ignoreGroups.includes(x));
};

const groupMap = {
  [PermissionGroups.None]: [...basicUserPages],
  [PermissionGroups.Master]: Object.values(Page).filter((value) => typeof value === 'number') as Page[], // Only numeric values (the enum values)
  [PermissionGroups.Administrator]: Object.values(Page).filter((value) => typeof value === 'number') as Page[], // Only numeric values
  [PermissionGroups.View_Referrals]: [...basicUserPages, Page.Referrals],
  [PermissionGroups.View_Workflows]: [...basicUserPages, Page.Workflows],
};

class Permission {
  isAdmin: boolean;
  groups: PermissionGroups[];

  constructor(groups: (string | PermissionGroups)[]) {
    this.groups = groups.length
      ? groups
          .map((group) => {
            // If it's a string, map to PermissionGroups enum; if it's already a PermissionGroup, use it directly
            return typeof group === 'string' ? PermissionGroups[group as keyof typeof PermissionGroups] : group;
          })
          .filter((group): group is PermissionGroups => group !== undefined) // Filter out invalid enum values
      : [PermissionGroups.None];

    this.isAdmin =
      this.groups.includes(PermissionGroups.Administrator) || this.groups.includes(PermissionGroups.Master);
  }

  GetPages(): Set<Page> {
    return new Set(this.groups.flatMap((x: PermissionGroups) => groupMap[x]));
  }

  IsRestricted(page: Page): boolean {
    try {
      if (this.isAdmin) {
        return false;
      }
      const pages = new Set(this.groups.flatMap((x: PermissionGroups) => groupMap[x]));
      return !pages.has(page);
    } catch (err) {
      logger.error(err);
    }
    return true;
  }
}

export default Permission;
