import React, { useState, FC, MouseEvent } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import User from '../../../shared/types/user';
import Grid from '@mui/material/Grid';
import { useSession } from '../../../components/hooks/useSession';
import { signUp } from '../../../api/auth/authRequests';
import { networkError } from '../../../api/base';
import Box from '@mui/material/Box/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import InfoTooltip from '../../../components/inputs/InfoTooltip';
import { passwordErrors, passwordsMatch, validatePassword } from '../../../shared/security/security';

interface Props {
  setUsers: React.Dispatch<React.SetStateAction<User[] | undefined>>;
  notify: (title: string, message: string, variant: 'success' | 'error' | 'warning' | 'info') => void;
  setAddUser: React.Dispatch<boolean>;
  setUserId: React.Dispatch<number>;
}

const AddUser: FC<Props> = (props) => {
  const { setUsers, notify, setAddUser, setUserId } = props;
  const [loading, setLoading] = useState(false);
  const { token } = useSession();
  const [passwordError, setPasswordError] = useState<string>();
  const [error, setError] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const clearFields = () => {
    setPasswordError(undefined);
    setPassword(undefined);
    setConfirmPassword(undefined);
  };

  const handlePasswordBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, compare: string) => {
    if (Boolean(compare) && !passwordsMatch(e.target.value, compare)) {
      setPasswordError('Passwords must match');
    } else {
      setPasswordError(undefined);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const email = formJson.email;
    const firstName = formJson.firstName;
    const lastName = formJson.lastName;
    const password = formJson.password;
    const confirmPassword = formJson.confirmPassword;
    const { message, isValid } = validatePassword(password, confirmPassword);

    if (!isValid) {
      setPasswordError(message);
      return;
    }

    const onSuccess = (user: User) => {
      setLoading(false);
      setUsers(undefined);
      setUserId(user.id);
      setAddUser(false);
      notify('Invitation Sent', 'A confirmation email has been sent!', 'success');
      clearFields();
    };

    const onFailure = (message: string | undefined) => {
      setLoading(false);
      setError(message || networkError);
    };
    setLoading(true);
    // No Abort Signal. Having issues with managing states between the two pages.
    await signUp(token?.accessToken, firstName, lastName, email, password, onSuccess, onFailure);
  };

  return (
    <Box sx={{ height: '86vh', width: '80vw' }} component={'form'} onSubmit={handleSubmit}>
      <IconButton
        aria-label="Back"
        onClick={() => {
          setAddUser(false);
        }}
      >
        <ArrowBackIcon fontSize="large" />
      </IconButton>
      <Box
        sx={{
          maxWidth: '80vw',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: '4rem',
        }}
      >
        <Grid container width={'20rem'} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography fontSize={'large'}>New User</Typography>
            {Boolean(error) && (
              <Typography variant="subtitle2" color="error" align="left" sx={{ width: '100%' }}>
                {error}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField required fullWidth id="lastName" label="Last Name" name="lastName" autoComplete="family-name" />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '105%' }}>
              <TextField
                required
                id="password"
                name="password"
                label="Password"
                fullWidth
                error={Boolean(passwordError)}
                onBlur={(e) => handlePasswordBlur(e, confirmPassword || '')}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(undefined);
                }}
                value={password}
                autoComplete={'new-password'}
                helperText={passwordError ? passwordError : ''}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <InfoTooltip
                title={
                  <>
                    {passwordErrors.map((x, index) => (
                      <span key={index}>
                        {x.error}
                        <br />
                      </span>
                    ))}
                  </>
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              required
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              fullWidth
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={(e) => handlePasswordBlur(e, password || '')}
              value={confirmPassword}
              autoComplete={'new-password'}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={0} sm={7}></Grid>
          <Grid item xs={12} sm={5}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained">
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default AddUser;
