import { useEffect, useState, useMemo, MouseEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import logger from '../../shared/logging/logger';
import { useSession } from '../hooks/useSession';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconLoader from '../loader/IconLoader';

const SignIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const abortController = useMemo(() => new AbortController(), []);
  const { login } = useSession();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')?.toString() ?? '';
    const password = data.get('password')?.toString() ?? '';

    const onSuccess = () => {
      setLoading(false);
    };

    const onFailure = (message: string) => {
      setError(message);
      setLoading(false);
    };
    setLoading(true);
    logger.debug('Calling api to sign in ', email);
    await login(email, password, abortController.signal, onSuccess, onFailure);
  };

  const clearError = () => {
    if (error) {
      setError('');
    }
  };

  useEffect(() => {
    return () => abortController.abort();
  }, [abortController]);

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>{loading ? <IconLoader /> : <LockOutlinedIcon />}</Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      {Boolean(error) && (
        <Typography variant="body2" color="error" align="left" sx={{ mt: 2, width: '100%' }}>
          {error}
        </Typography>
      )}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: error ? 1 : 5 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
          error={Boolean(error)}
          onBlur={clearError}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" fullWidth disabled={loading} variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
      </Box>
    </>
  );
};

export default SignIn;
