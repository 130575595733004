import Token from '../../../shared/types/token';
import requestType from '../requestType';
import logger from '../../../shared/logging/logger';
import MessageStatus from '../../../shared/enums/messageStatus';
import WorkflowResultSummary from '../../../shared/types/workflowResultSummary';

export const authRequest = (token: Token | undefined) => {
  return {
    requestType: requestType.Auth,
    data: {
      AccessToken: token?.accessToken,
    },
  };
};

export const getReferrals = () => {
  logger.debug('Sending referral request');
  return {
    requestType: requestType.GetReferrals,
  };
};

export const updateMessageStatus = (patientId: number, messageStatus: MessageStatus) => {
  return {
    requestType: requestType.UpdateMessage,
    data: {
      patientId,
      messageStatus,
    },
  };
};

export const trackSummaries = (summaries: WorkflowResultSummary[]) => {
  return {
    requestType: requestType.TrackSummaries,
    data: {
      summaries: summaries,
    },
  };
};
