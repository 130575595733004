import React from 'react';
import { Divider, Theme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { dateTimeFormatAlt, utcToLocal } from '../../shared/dates/date';
import WorkflowResult from '../../shared/types/workflowResult';
import Schedule from '../../shared/types/schedule';
import WorkflowStatus from '../../shared/enums/workflowStatus';
import CaregiverList from './CaregiverList';

export const columns = (theme: Theme, onRowExpand: (id: number, count: number, show: boolean) => void): GridColDef<WorkflowResult<Schedule[]>>[] => [
  {
    field: 'id',
    headerName: 'Run ID',
    minWidth: 100,
    width: 150,
  },
  {
    field: 'start',
    headerName: 'Start Time',
    minWidth: 100,
    valueGetter: (value) => (value ? dateTimeFormatAlt.format(new Date(utcToLocal(value))).replace(',', '') : value),
    width: 300,
  },
  {
    field: 'end',
    headerName: 'End Time',
    minWidth: 100,
    width: 300,
    valueGetter: (value) => (value ? dateTimeFormatAlt.format(new Date(utcToLocal(value))).replace(',', '') : value),
  },
  {
    field: 'success',
    headerName: 'Run Status',
    minWidth: 100,
    width: 200,
    renderCell: (params: GridRenderCellParams<WorkflowResult<Schedule[]>, boolean>) => (
      <span style={{ color: params.value ? theme.palette.success.main : theme.palette.error.main }}>
        {params.value ? 'Completed' : 'Failed'}
      </span>
    ),
  },
{
field: 'records',
headerName: 'Summary',
flex: 1,
minWidth: 300,
width: 500,
renderCell: (params: GridRenderCellParams<WorkflowResult<Schedule[]>, number>) => {
  if (!params.row.success || params.value === 0) {
    return <span>No records to process</span>;
  } else {
    return (
      <div
        style={{
          lineHeight: 'normal',
          paddingTop: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div>Total records processed: {params.value}</div>
        <Divider style={{ width: '11rem' }} />
        <ul style={{ paddingLeft: '20px', margin: 0 }}>
          {Boolean(params.row.createdCount) && (
            <CaregiverList
              id={params.row.id}
              onRowExpand={onRowExpand}
              count={params.row.createdCount}
              data={params.row.data}
              status={WorkflowStatus.Created}
            />
          )}
          {Boolean(params.row.updatedCount) && (
            <CaregiverList
              id={params.row.id}
              onRowExpand={onRowExpand}
              count={params.row.updatedCount}
              data={params.row.data}
              status={WorkflowStatus.Updated}
            />
          )}
          {Boolean(params.row.failedCount) && (
            <CaregiverList
              id={params.row.id}
              onRowExpand={onRowExpand}
              count={params.row.failedCount}
              data={params.row.data}
              status={WorkflowStatus.Error}
            />
          )}
        </ul>
      </div>
    );
  }
}
}];



