import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import React, { useState, FC } from 'react';
import WorkflowDrawer from '../../components/workflows/WorkflowDrawer';
import { NotifyCallback } from '../../shared/types/callbacks';
import { WorkflowPage } from '../../shared/types/pages';
import WorkflowHistory from './WorkflowHistory';

interface Props {
  notify: NotifyCallback;
}

const Workflows: FC<Props> = (props) => {
  const [selectedWorkflow, setSelectedWorkflow] = useState(0);
  const [workflowType, setWorkflowType] = useState(0);
  const [selectedPage, setSelectedPage] = useState(WorkflowPage.Run_History);
  const [openDrawer, setOpenDrawer] = useState(true);

  const onSelectWorkflow = (id: number, type: number) => {
    setSelectedWorkflow(id);
    setWorkflowType(type);
    setSelectedPage(WorkflowPage.Run_History);
  };

  const onSelectWorkflowPage = (page: WorkflowPage) => setSelectedPage(page);

  const childProps = {
    ...props,
    onSelectWorkflow,
    onSelectWorkflowPage,
    selectedWorkflow,
    selectedPage,
    openDrawer,
    setOpenDrawer,
    setSelectedWorkflow,
    data: [],
  };

  return (
    <Grid
      container
      sx={{
        paddingTop: '52px', // Navbar: height: 32px; paddingTop: 10px; paddingBottom: 10px
        height: 'calc(100vh - 52px)',
      }}
    >
      <WorkflowDrawer {...childProps} />
      <Grid item xs={openDrawer ? 10 : 12} sx={{ height: '100%', width: '100%', ml: openDrawer ? '280px' : undefined }}>
        <Paper
          elevation={3}
          sx={{
            padding: '20px 50px 60px 50px',
            borderRadius: '20px',
            position: 'relative',
            height: 'calc(100% - 32px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          {selectedPage === WorkflowPage.Run_History && Boolean(selectedWorkflow) && (
            <WorkflowHistory selectedWorkflow={selectedWorkflow} type={workflowType} {...props} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Workflows;
